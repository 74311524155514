import { ChangeDetectorRef, Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { DataSetRequest, DateRange } from 'src/app/shared/charts/dashboardchart/ChartConfiguration';
import { Router } from '@angular/router';
import { ReportingService } from 'src/app/Services/reporting.service.spec';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ChartDataResponse } from './ChartResponse';
import { ChartStateHandler } from './chart-state/chartstate-handler';
import { ChartDataHandler } from './chart-state/chartdata-handler';
import { UserInterfaceHandler } from './chart-state/userinterface-handler';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';
import { ChartConfiguration, ChartType, DateRangeOption, GroupByOption } from './ChartConfiguration';

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss']
})
export class DashboardChartComponent implements OnInit, AfterViewInit {

  // Handler instances
  public chartDataHandler!: ChartDataHandler;
  public chartStateHandler!: ChartStateHandler;
  public userInterfaceHandler!: UserInterfaceHandler;

  // Component state
  public chartConfig: ChartConfiguration | null = null;
  public chartDataResponse!: ChartDataResponse;
  public dateRange: DateRange = { start: '', end: null };
  public lastUpdated: Date = new Date();
  @ViewChild('chart') chart!: GenericChartComponent;

  // Input properties (alphabetically ordered)
  @Input() asPercentage = false;
  @Input() bucketSize?: number;
  @Input() chartType: ChartType = 'bar';
  @Input() colors: string[] = [
    '#006400',      // Dark Green
    '#90EE90',      // Light Green
    '#228B22',      // Forest Green
    '#B0FFB0',      // Pale Green
    '#008000',      // Medium Green
    '#C8FFC8',      // Very Light Green
    '#2E8B57',      // Sea Green
    '#BEF0BE',      // Mint Green
    '#556B2F',      // Dark Olive Green
    '#BCEEAE'       // Light Olive Green
  ];
  @Input() dataSetRequests: DataSetRequest[] = [];
  @Input() EndDate = '';
  @Input() GroupBy?: GroupByOption;
  @Input() hideStatistics = false;
  @Input() isFullPage = false;
  @Input() height : number | undefined;
  @Input() isLive = false;
  @Input() selectedDateRange: DateRangeOption = 'custom';  
  @Input() ShowFilters = true;
  @Input() stacked = false;
  @Input() StartDate = '';
  @Input() title = 'Dashboard Chart';
  @Input() yAxisLabel?: string;

  constructor(private cdr: ChangeDetectorRef, private router: Router, 
    public reportingService: ReportingService, private modalService: SimpleModalService, 
    public elementRef: ElementRef, public toastr: ToastrService) {  
    this.chartStateHandler = new ChartStateHandler(this.router, this);
    this.chartDataHandler = new ChartDataHandler(this, this.reportingService, this.toastr, this.cdr, this.modalService);
    this.userInterfaceHandler = new UserInterfaceHandler(this);
    this.chartStateHandler.loadState();
    this.chartStateHandler.saveState();
  }

  ngOnInit(): void {
    this.userInterfaceHandler.setFullPage();
    this.dateRange = {
      start: this.chartConfig?.startDate ?? this.StartDate ?? new Date(new Date().setHours(0,0,0,0)).toISOString().slice(0,16),
      end: this.chartConfig?.endDate ?? this.EndDate ?? new Date().toISOString().slice(0,16)
    };

    this.userInterfaceHandler.UpdateOrCheckIsLive();
  }

  

  ngAfterViewInit(): void {
    this.chartDataHandler.FetchChartData();
  }

  ngOnDestroy() {
    this.reportingService.deregisterChart(this);
    this.chartStateHandler.clearState();
    this.userInterfaceHandler.clearFullPage();
  }
}
