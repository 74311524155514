import { Injectable } from "@angular/core";
import { DashboardChartComponent } from "../dashboard-chart.component";

@Injectable({
    providedIn: 'root'
})

export class UserInterfaceHandler {
    private dashboardChart!: DashboardChartComponent;

    constructor(dashboardChart: DashboardChartComponent) {
        this.dashboardChart = dashboardChart;
    }

    /**
     * Checks if the dashboard contains multiple bar charts
     * Used to turn on the stacked view for bar charts
     * @returns true if there is more than one bar chart in the dashboard
     */
    HasMultipleBarCharts(): boolean {
        const barCharts = this.dashboardChart.dataSetRequests.filter(chart => chart.ChartType === 'bar');
        return barCharts.length > 1;
    }
    canShowMetricGrouping(): boolean {
        return this.dashboardChart.dataSetRequests.length === 1 && 
               this.dashboardChart.dataSetRequests[0].Metric !== undefined && 
               (this.dashboardChart.reportingService.getReportableProperty(
                   this.dashboardChart.dataSetRequests[0].TargetType, 
                   this.dashboardChart.dataSetRequests[0].Metric
               )?.CanGroupBy ?? false);
    }

    /**
     * Checks if the dashboard contains a pie chart
     * Used to determine whether more than one data set can be added to the dashboard
     * @returns true if at least one pie chart exists in the dashboard
     */
    HasPieChart(): boolean {
        return this.dashboardChart.dataSetRequests.some(dataset => dataset.ChartType === 'pie');
    }

    UpdateOrCheckIsLive() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Set isLive to true if endDate is null or >= today
        const endDate = this.dashboardChart.dateRange.end ? new Date(this.dashboardChart.dateRange.end) : null;
        if (!endDate || endDate >= today) {
          this.dashboardChart.isLive = true;
        }
        // Check if all datasets have no filters and are standard Count aggregations
        const hasOnlySimpleCountDatasets = this.dashboardChart.dataSetRequests.every(dataset => 
          (!dataset.Filters || dataset.Filters.length === 0) && 
          dataset.AggregateType === 'Count' && this.dashboardChart.GroupBy != 'metric' && dataset.SplitBy == undefined
        );
        this.dashboardChart.isLive = this.dashboardChart.isLive && hasOnlySimpleCountDatasets;

        if(this.dashboardChart.isLive) {
            this.dashboardChart.reportingService.registerChart(this.dashboardChart);
        }
        else{
            this.dashboardChart.reportingService.deregisterChart(this.dashboardChart);
        }
    }

    getMinDate(): string {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 7);
        return date.toISOString().slice(0, 16); // Format as YYYY-MM-DDTHH:mm
    } 

    setFullPage() {
        if (this.dashboardChart.chartConfig?.isFullPage) {
            const mainContent = this.dashboardChart.elementRef.nativeElement.closest('.maincontent');
            mainContent?.classList.add('fullpage');
        }
    }

    /**
     * Removes the full page class from the main content element if the chart is no longer in full page mode.
     */
    clearFullPage() {
        if (this.dashboardChart.chartConfig?.isFullPage) {
            const mainContent = this.dashboardChart.elementRef.nativeElement.closest('.maincontent');
            mainContent?.classList.remove('fullpage');
        }
    }
    
    determineYAxisLabel(): string {
        if (!this.dashboardChart.dataSetRequests?.length) return '';
        
        return this.dashboardChart.dataSetRequests.map(dataset => {
            if (dataset.AggregateType && dataset.AggregateType !== 'None') {
                return `${dataset.AggregateType} of ${this.dashboardChart.reportingService.getReportableProperty(dataset.TargetType, dataset.Metric)?.Label}${
                    dataset.SplitBy ? ' by ' + this.dashboardChart.reportingService.GetSplitByLabel(dataset.TargetType, dataset.SplitBy) : ''
                }`;
            }
            
            return this.dashboardChart.reportingService.getReportableProperty(dataset.TargetType, dataset.Metric)?.Label || '';
        }).join(' / ');
    }

    getYAxisLabel() {
        this.dashboardChart.yAxisLabel = this.determineYAxisLabel();
    }
}