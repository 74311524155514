import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { access } from 'fs';

@Component({
  selector: 'app-editcontrolledareaaccessmethod',
  templateUrl: './editcontrolledareaaccessmethod.component.html',
  styleUrls: ['./editcontrolledareaaccessmethod.component.scss']
})
export class EditcontrolledareaaccessmethodComponent extends SimpleModalComponent<any, any> implements OnInit {
  Form !: UntypedFormGroup;

  public RateSets: any[] | null = null;
  public AccessLists: any[] | null = null;
  constructor(private apiService: ApiServiceBase) {
    super();
  }

  public NewMethod: boolean = true;

  ngOnInit(): void {
    this.Form.addControl('ChargeStrategy', new UntypedFormControl('None'));
    this.Form.addControl('HoldChargeAmount', new UntypedFormControl(null));

    if(this.Form.get('Id')) {
      this.NewMethod = false;
    }
    
    this.apiService.Post<any>("parking/ratesets/searches", { PageNumber: 1, PageSize: 9999, Page: 1 })
      .then(result => {
        this.RateSets = result.Results;
      });
    this.apiService.Post<any>("parking/plateaccess/searches", { PageNumber: 1, PageSize: 9999, Page: 1 })
      .then(result => {
        this.AccessLists = result.Results;
      });
  }

  public GetClassName(): any {
    return this.Form.get('ClassName')?.value;
  }

  public ClassChanged() {
    let name = this.GetClassName();    
    let accessMethodDesc = this.Form.get('AccessMethodDescription');
    if (name == 'ControlledAreaAccessMethodWhiteList') {
      let accessListName = this.Form.get('PlateAccessListName');
      if (accessListName == null) {
        this.Form.addControl('PlateAccessListName', new UntypedFormControl());
      }

      let accessListId = this.Form.get('PlateAccessListId');
      if (accessListId == null) {
        this.Form.addControl('PlateAccessListId', new UntypedFormControl());
      }
      accessMethodDesc?.setValue("Access List Only");
    }
    if (name == 'ControlledAreaAccessMethodPublic') {
      let accessListName = this.Form.get('PlateAccessListName');
      if (accessListName != null) {
        accessListName.setValue(null);
      }

      let accessListId = this.Form.get('PlateAccessListId');
      if (accessListId != null) {
        accessListId.setValue(null);
      }
      accessMethodDesc?.setValue("Public Access")
    }
  }
  public RateSetChanged() {
    let x = this.Form.get('RateSetName');
    let id = this.Form.get('RateSetId')?.value;
    let set = this.RateSets?.filter(x => x.Id == id)[0];
    x?.setValue(set.Name);
    this.UpdateChargeStrategyDescription();

    /*if (this.RateSets == null) {
      return;
    }
    let set = this.RateSets.filter(x => x.Id == this.Model.RateSetId)[0];
    this.Model.RateSetName = set.Name;*/
  }

  public AccessListChanged() {
    let x = this.Form.get('PlateAccessListName');
    let id = this.Form.get('PlateAccessListId')?.value;
    let set = this.AccessLists?.filter(x => x.Id == id)[0];
    x?.setValue(set.Name);

    
    let accessMethodDesc = this.Form.get('AccessMethodDescription');
    accessMethodDesc?.setValue("Vehicles on " + set.Name);

    /*if (this.AccessLists == null) {
      return;
    }
    let set = this.AccessLists.filter(x => x.Id == this.Model.PlateAccessListId)[0];
    this.Model.PlateAccessListName = set.Name;
    */
  }

  public GetChargeStrategy(): string {
    return this.Form.get('ChargeStrategy')?.value;
  }

  public UpdateChargeStrategyDescription() {
    let id = this.Form.get('RateSetId')?.value;
    let set = this.RateSets?.filter(x => x.Id == id)[0];
    let csd = this.Form.get('ChargeStrategyDescription');
    let chargeAmt = this.Form.get('HoldChargeAmount');
    let ChargeStrategy = this.GetChargeStrategy();

    switch(ChargeStrategy) {
      case "None":
        csd?.setValue("No charges apply");
        break;
      case "Hold":
        csd?.setValue(`Holds ${chargeAmt?.value ?? 0} on entry, then charges ${set?.Name ?? "<not set>"} on exit`);
        break;
      case "PrePay":
        csd?.setValue(`Charges ${set?.Name} on entry`);
        break;
      case "PostPay":
        csd?.setValue(`Charges ${set?.Name} on exit`);
        break;
    }
  }

  public ShowRateSet(): boolean {
    const ChargeStrategy = this.GetChargeStrategy();
    return ChargeStrategy === 'PostPay' || ChargeStrategy === 'PrePay' || ChargeStrategy === 'Hold';
  }

  public ChargeStrategyChanged() {
    const ChargeStrategy = this.GetChargeStrategy();
    
    const HoldChargeAmountControl = this.Form.get('HoldChargeAmount');
    if (ChargeStrategy === 'Hold') {
      if (HoldChargeAmountControl) {
        HoldChargeAmountControl.setValidators([Validators.required, Validators.min(0)]);
      }
    } else {
      if (HoldChargeAmountControl) {
        HoldChargeAmountControl.clearValidators();
        HoldChargeAmountControl.setValue(null);
      }
    }
    
    const rateSetControl = this.Form.get('RateSetId');
    if (ChargeStrategy === 'None') {
      if (rateSetControl) {
        rateSetControl.clearValidators();
        rateSetControl.setValue(null);
      }
    } else {
      if (rateSetControl) {
        rateSetControl.setValidators([Validators.required]);
      }
    }

    if (HoldChargeAmountControl) HoldChargeAmountControl.updateValueAndValidity();
    if (rateSetControl) rateSetControl.updateValueAndValidity();
    this.UpdateChargeStrategyDescription();
  }

  cancel() {
    this.result = false;
    this.close();
  }

  confirm() {
    this.result = true;
    this.close();
  }
}