import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Geo } from 'src/app/util/geo';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from 'src/app/Services/media.service';
import { GoogleMap } from '@angular/google-maps';
import { CarCounterStructureItem, GatewayStructureItem, GuidanceLightStructureItem, ParkingLevelStructure, ParkingLevelStructurePointItem, ParkingLevelStructurePolygonItem, ParkingLotStructureItem, ParkingSpaceStructureItem, ParkingStructurePointItem, ParkingStructurePolygonItem, SensorStructureItem, SignStructureItem, StructureParkingLevelMapItem, StructureViewerItemBase, TerminalStructureItem } from './structureviewermapitem';
import { ParkingLevelMapItem, ParkingSpaceMapItem } from 'src/app/features/parking/parking/parkinglevels/edit/designer/levelmapeditor/mapitems';
import { AvailableLayers, StructureViewerLayer } from './structureviewerlayers';
import { SignsService } from 'src/app/Services/signsservice';
import { Datalistrequestfilter } from '../datalistview/request/datalistrequestfilter';
import { GuidanceFeedService } from 'src/app/Services/guidancefeed.service';

@Component({
  selector: 'app-structureviewermaps',
  templateUrl: './structureviewermaps.component.html',
  styleUrls: ['./structureviewermaps.component.scss']
})

export class StructureViewerMapsComponent extends Busyable implements OnInit, AfterViewInit {
  @Input() public Layers !: StructureViewerLayer[];
  @Input() public ShowLotSelect : boolean = false;
  @Input() public ShowLevelSelect : boolean = false;
  @Output()
  public SelectedValueChanged = new EventEmitter<any>();
  @ViewChild('map') map !: GoogleMap;
  public MapOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: true 
  };
  
  @Input()
  public SearchTerm !: string;
  @Input() 
  public StructureViewerForType !: string;
  public response: any = null;
  public SelectedItem !: StructureViewerItemBase | null;
  public AvailableLayers: StructureViewerLayer[] = AvailableLayers;
  public Busyable : Busyable = new Busyable;
  public MapItems: StructureViewerItemBase[] = [];
  public PingSensors: any[] = [];
  public MapItemsByType: Map<string, StructureViewerItemBase[]> = new Map<string, StructureViewerItemBase[]>();

  public ParkingLots !: any[];
  public ParkingLevels !: any[];

  public ParkingLotId !: string | null;
  public ParkingLevelId !: string | null;
  @Input() public StructureId !: string | null;

  public InfoWindow : google.maps.InfoWindow = new google.maps.InfoWindow({
    content: '<div style="width: 200px; height: 100px;">Custom Content Goes Here</div><div id="infoWindowButton"></div>',
    ariaLabel: "Uluru",
  });

  constructor(public apiservice: ApiService,public router: Router,public sanitizer: DomSanitizer,public mediaService: MediaService, public signService: SignsService, public guidanceFeed: GuidanceFeedService) {
    super();
  }

  public LotForStructure(item: any){
    if(this.StructureId == null){
      return false;
    }
    return !(item.Id == this.StructureId || item.ParentId == this.StructureId);
  }

  public SearchForParkingLots(){
    var Filter = {
      PageNumber: 1,
      PageSize: 30,
      SearchTerm: "",
      Subset: "",
      Filters: [] as Array<Datalistrequestfilter>,
      OrderByProperty: "",
      Ascending: false
    };

    this.apiservice.Post('infrastructure/parkinglots/searches', Filter).then((response: any) => {
      this.ParkingLots = response.Results;
    })
  }

  public LotsOrZones(){
    var plot = this.ParkingLots.find(x => x.Id == this.ParkingLotId);
    if(plot == null){
      return "Levels";
    }
    return (plot.Type > 0) ? "Levels" : "Zones";
  }
  
  public SearchForParkingLevels(){
    this.apiservice.Get('infrastructure/parkinglots/' + this.ParkingLotId + '/levels', ).then((response: any) => {
      this.ParkingLevels = response;
    })
  }

  ngOnInit(): void {
    this.SearchForParkingLots();
    this.SearchForItems();

    if(this.Layers.some(x => x.Type == "Sign")){
      this.signService.SignValueChanged.subscribe((result: any) => {
        this.UpdateSign(result.Id, result.SignValue);
      })
    }

    if(this.Layers.some(x => x.Type == "IRSensor")){
      this.guidanceFeed.GuidanceFeedServiceUpdated.subscribe((result: any) => {
        this.UpdateSensor(result.SpaceId, result.MessageType);
      })
    }
  };

  public SearchForItems(){
    this.Busyable.Loading();
    this.ClearMapItems();
    this.apiservice.Post('infrastructure/structures/' + (this.ParkingLevelId ?? this.ParkingLotId ?? this.StructureId ?? ""), { IncludeLayers : this.Layers, SearchTerm: this.SearchTerm }).then(response => {
      this.response = response;
      this.AddMapItems(response);
      this.Busyable.StopLoading();
      this.map.googleMap?.setZoom(18);

      //pan to the specified structure
      if (this.StructureId != null) {
        const item = this.MapItems.find(x => x.Id === this.StructureId);
        if(item instanceof ParkingLotStructureItem){
          this.ParkingLotId = this.StructureId;
          this.SearchForParkingLevels();
        }
        if (item instanceof ParkingStructurePolygonItem) {
            const panToLocation = item.GeoLocationPoints || item.GeoPolygonPoints[0];
            this.map.googleMap?.panTo(panToLocation);
        }
      }

      //explicit results and an info window for a single item (e.g Showing popup on a parking space on a ParkingSession)
      if(this.StructureViewerForType != null){
        var items = this.MapItems.filter(x => x.ClassName == this.StructureViewerForType);

        if(this.SearchTerm != null){
          items = items.filter(x => x.Id == this.SearchTerm);
        }

        if(items){
          if (items[0] instanceof ParkingStructurePolygonItem) {
            const panToLocation = items[0].GeoLocationPoints || items[0].GeoPolygonPoints[0];
            this.map.googleMap?.panTo(panToLocation);
          } 
          else if (items[0] instanceof ParkingStructurePointItem) {
            const panToLocation = items[0].GeoLocationPoints;
            this.map.googleMap?.panTo(panToLocation);
          } 

          if(this.SearchTerm != null){
            this.InfoWindowForItem(items[0]);
          }
          return;
        }
      }
      //otherwise, pan to first map item
      this.PanToFirstMapItem();
    })
  }

  public PanToFirstMapItem(){
    var MapItem = this.MapItems[0];

    if(MapItem instanceof ParkingStructurePolygonItem){
      this.map.googleMap?.panTo(MapItem.GeoPolygonPoints[0]);
    }
    else if(MapItem instanceof ParkingStructurePointItem){
      this.map.googleMap?.panTo(MapItem.GeoLocationPoints);
    }
  }

  ngAfterViewInit(): void {
  }

  public AddMapItems(response: any) {
    this.processLayers(response.Layers.filter((x: any) => x.Type != "ParkingSpace"));
    this.processLayers(response.Layers.filter((x: any) => x.Type == "ParkingSpace"));
  }
  
  private processLayers(layers: any[]) {
    layers.forEach((layer: any) => {
      this.ProcessLayer(layer);
    });
  }
  
  public ProcessLayer(layer: any) {
    switch (layer.Type) {
      case "ParkingLot":
        this.AddParkingLotMapItems(layer);
        break;
      case "ParkingLevel":
        this.AddParkingLevel(layer);
        break;
      case "ParkingSpace":
        this.AddParkingLevelSpace(layer);
        break;
      case "SignConfiguration":
        this.AddParkingLevelSign(layer);
        break; 
      case "GatewayConfiguration":
          this.AddParkingLevelGateway(layer);
          break; 
      case "GuidanceLightConfiguration":
        this.AddParkingLevelGuidanceLight(layer);
        break; 
      case "CarCounterConfiguration":
        this.AddCarCounter(layer);
        break; 
      case "IRSensor":
          this.AddParkingLevelSensor(layer);
        break;
      case "LocalController":
          this.AddParkingLevelTerminal(layer);
        break;
      case "ParkingLevelMapItem":
        this.AddStructureParkingLevelMapItem(layer);
        break;
    }
  }

  public AddParkingLotMapItems(layer: any){
    layer.Items.forEach((item: any) => {
      var lot = new ParkingLotStructureItem(item);
      this.AddPolygonForItem(lot);
      this.PushToMapItems(lot, layer.Type);
    });
  }

  public AddParkingLevel(layer: any){
    layer.Items.forEach((item: any) => {
      var level = new ParkingLevelStructure(item);
      this.AddPolygonForItem(level);
      this.PushToMapItems(level, layer.Type);
    });
  }

  public AddCarCounter(layer: any){
    layer.Items.forEach((item: any) => {
      var level = new CarCounterStructureItem(item);
      this.AddPointForItem(level);
      this.PushToMapItems(level, layer.Type);
    });
  }

  public AddParkingLevelSpace(layer: any){
    console.log(layer.Items.length + 'spaces in response');
    layer.Items.forEach((item: any) => {
      var space = new ParkingSpaceStructureItem(item);
      this.AddPolygonForItem(space);
      this.PushToMapItems(space, layer.Type);
    });
  }

  public AddParkingLevelSign(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new SignStructureItem(item);
      this.AddPointForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }


  public AddParkingLevelGateway(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new GatewayStructureItem(item);
      this.AddPointForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }

  
  public AddParkingLevelGuidanceLight(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new GuidanceLightStructureItem(item);
      this.AddPointForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }

  public AddParkingLevelCarCounter(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new CarCounterStructureItem(item);
      this.AddPointForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }

  public AddParkingLevelTerminal(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new TerminalStructureItem(item);
      this.AddPointForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }

  public AddStructureParkingLevelMapItem(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new StructureParkingLevelMapItem(item);
      this.AddPolygonForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }

  public AddParkingLevelSensor(layer: any){
    layer.Items.forEach((item: any) => {
      var mapItem = new SensorStructureItem(item);
      this.AddPointForItem(mapItem);
      this.PushToMapItems(mapItem, layer.Type);
    });
  }


  public PushToMapItems(item: StructureViewerItemBase, type: string){
    this.MapItems.push(item);
    if (this.MapItemsByType.has(type)) {
      this.MapItemsByType.get(type)?.push(item);
    }
    else {
      this.MapItemsByType.set(type, [item]);
    }
  }

  public SelectLot(event: any)
  {
    if(event.target.value == "All"){
      this.ParkingLevelId = null;
      this.ParkingLotId = null;
      this.SearchForItems();
      return;
    }
    this.ParkingLotId = event.target.value;
    this.SearchForParkingLevels();
    this.SearchForItems();
  }

  public SelectLevel(event: any)
  {
    if(event.target.value == "All"){
      this.ParkingLevelId = null;
      this.SearchForItems();
      return;
    }
    this.ParkingLevelId = event.target.value;
    this.SearchForItems();
  }

  public MultipleItemsOfType(key: string): boolean {
    const items = this.MapItemsByType.get(key);
    return items ? items.length > 1 : false;
  }

  public SetSearchTerm(term: string){
    this.SearchTerm = term;
  }

  public UpdateSign(Id: string, SignValue: number){
    var sign = this.MapItems.filter(x => x.Id == Id);
    if(sign){
      (sign[0] as SignStructureItem).UpdateMarkerNumber(SignValue);
      const zoomLevel = this.map.googleMap?.getZoom();
      // Adjust marker size based on zoom level
      if(zoomLevel){
        (sign[0] as SignStructureItem).BuildNewUrlForNumber(zoomLevel);
      }
    }
  }

  getMarkerIcon(isOccupied: boolean): google.maps.Icon {
    return {
      url: `assets/deviceicons/${isOccupied ? 'occupied' : 'vacant'}_ping.gif`,
      anchor: new google.maps.Point(13, 13)
    };
  }

  public UpdateSensor(SpaceId: string, MessageType: string){
    switch(MessageType){
      case "CarOnEvent":
        {
          let spaceId : string | null = SpaceId?.toLowerCase();
          let space = this.MapItems.find((s : any) => (s instanceof SensorStructureItem) && (s as SensorStructureItem).ParkingSpaceId == spaceId);
          if(space != null){
            var item = (space as SensorStructureItem);
            item.IsOccupied = true;
            item.getMarkerIcon();
            this.PingSensors.push(item);
            //remove from ping list after a second
            setTimeout(() => {
              let index = this.PingSensors.indexOf(item);
              if(index > -1){
                this.PingSensors.splice(index, 1);
              }
            }, 4000);
            //remove from ping list after a second
          }
        }
        break;
      case "CarOffEvent":
        {
          let spaceId : string | null = SpaceId?.toLowerCase();
          let space = this.MapItems.find((s : any) => (s instanceof SensorStructureItem) && (s as SensorStructureItem).ParkingSpaceId == spaceId);
          if(space != null){
            var item = (space as SensorStructureItem);
            item.IsOccupied = false;
            item.getMarkerIcon();
            this.PingSensors.push(item);
            //remove from ping list after a second
            setTimeout(() => {
              let index = this.PingSensors.indexOf(item);
              if(index > -1){
                this.PingSensors.splice(index, 1);
              }
            }, 4000);
          }
        }
        break;
    }
  }

  public ClearMapItems(){
    this.MapItems?.forEach((item : StructureViewerItemBase) =>{
      if(item instanceof ParkingStructurePolygonItem){
        if(item.MapPolygon){
          item.MapPolygon.setMap(null);
        }
      }
      else if(item instanceof ParkingStructurePointItem){
        if(item.MapPoint){
          item.MapPoint.setMap(null);
        }
      }
    });
    this.MapItems = [];
    this.MapItemsByType.clear();
  }

  public HideAllMapItems(){
    this.MapItems?.forEach((item : StructureViewerItemBase) =>{
      if(item instanceof ParkingStructurePolygonItem){
        if(item.MapPolygon){
          item.MapPolygon.setMap(null);
        }
      }
      else if(item instanceof ParkingStructurePointItem){
        if(item.MapPoint){
          item.MapPoint.setMap(null);
        }
      }
    });
  }

  public DeselectAllMapItems(){
    this.MapItems?.forEach((item : StructureViewerItemBase) =>{
      if(item instanceof ParkingStructurePolygonItem){
        if(item.MapPolygon && this.map.googleMap){
          item.MapPolygon.setOptions({
            strokeColor: item.StrokeColor,
            strokeWeight:item.StrokeWeight
          });
        }
      }
      if(item instanceof SignStructureItem){
        item.ZoomLevel = this.map.googleMap?.getZoom();
        item.Selected = false;
        item.BuildNewUrlForNumber(item.ZoomLevel);
      }
    });
    this.SelectedItem = null;
  }

  public ShowAllMapItems(){
    this.MapItems?.forEach((item : StructureViewerItemBase) =>{
      if(item instanceof ParkingStructurePolygonItem){
        if(item.MapPolygon && this.map.googleMap){
          item.MapPolygon.setMap(this.map.googleMap);
        }
      }
      else if(item instanceof ParkingStructurePointItem){
        if(item.MapPoint && this.map.googleMap){
          item.MapPoint.setMap(this.map.googleMap);
        }
      }
    });
  }

  public AddPolygonForItem(item: ParkingStructurePolygonItem) {
    if (item.MapPolygon != null && this.map && this.map.googleMap) {
      item.MapPolygon.setMap(this.map.googleMap);
      item.MapPolygon.addListener("click", () => this.SelectStructureMapItem(item.Id));
    }
  }


  public AddPointForItem(item: ParkingStructurePointItem) {
    if (this.map && this.map.googleMap) {
      item.MapPoint.setMap(this.map.googleMap);

      item.MapPoint.addListener("click", () => {
        this.SelectStructureMapItem(item.Id);
      });

      if(item instanceof SignStructureItem){
        google.maps.event.addListener(this.map.googleMap, 'zoom_changed', () => {
          const zoomLevel = this.map.googleMap?.getZoom();
          // Adjust marker size based on zoom level
          if(zoomLevel){
            (item as SignStructureItem).BuildNewUrlForNumber(zoomLevel);
          }
        });
      }
    }
  }

  public SelectStructureMapItem(id: string | undefined, pan: boolean = false){
    let item = this.MapItems.find(x => x.Id == id) as StructureViewerItemBase;
    if(this.SelectedItem!= null){
      if(this.SelectedItem?.Id == item.Id){
        this.InfoWindow.close();
        this.DeselectAllMapItems();
        return;
      }
      this.InfoWindow.close();
      this.DeselectAllMapItems();
    }
    this.SelectedItem = item;

    if(item instanceof ParkingStructurePolygonItem){
      item.MapPolygon.setOptions({
        strokeColor: item.FillColor,
        strokeWeight: 4
      });

      if(pan && this.map.googleMap){
        this.map.googleMap.panTo((item as ParkingStructurePolygonItem).GeoPolygonCenter);
      }
    }
    if(item instanceof ParkingStructurePointItem){
      if(pan && this.map.googleMap){
        this.map.googleMap.panTo((item as ParkingStructurePointItem).GeoLocationPoints);
      }
      if (item instanceof SignStructureItem){
        var currentZoomLevel = this.map.googleMap?.getZoom();
        item.Selected = true;
        item.BuildNewUrlForNumber(currentZoomLevel);
      }
    }

    if(item instanceof StructureParkingLevelMapItem){
      return;
    }

    this.InfoWindowForItem(item);

  }

  public BuildInfoWindowContent(item: any) {
    const buttonHtml = item.Reroute != null
      ? `<div class="button" id="infoWindowButton" style="width:100%; background-color:darkgray; color: white;">View</div>`
      : "";
    
    return `<div class="tooltip" style="width:180px; height:120px;">
      <div class="pointer"></div>
      <div class="description" style="height:80px; line-height: 1.5">${item.Description}</div>
      ${buttonHtml}
    </div>`;
  }


  public Redirect(redirect: string){
    this.router.navigate([redirect]);
  }

  public InfoWindowForItem(item: any){
    this.SelectedValueChanged.emit(item.Id);
    this.InfoWindow.setContent(this.BuildInfoWindowContent(item));

    if(item instanceof ParkingLevelStructurePointItem){
      this.InfoWindow.setPosition(item.GeoLocationPoints);
    }
    else if(item instanceof ParkingStructurePolygonItem){
      this.InfoWindow.setPosition(item.GeoPolygonCenter);
    }
    else if(item instanceof ParkingLevelStructurePolygonItem){
      this.InfoWindow.setPosition(item.GeoPolygonCenter);
    }
    this.InfoWindow.open(this.map?.googleMap);
    this.InfoWindow.addListener("closeclick", () => {

      if(this.SelectedItem instanceof ParkingStructurePolygonItem){
        this.SelectedItem?.MapPolygon.setOptions({
          strokeColor: this.SelectedItem.StrokeColor,
          strokeWeight:this.SelectedItem.StrokeWeight
        })
      }
      if(this.SelectedItem instanceof SignStructureItem){
        var currentZoomLevel = this.map.googleMap?.getZoom();
        this.SelectedItem.Selected = false;
        this.SelectedItem.BuildNewUrlForNumber(currentZoomLevel);
      }
      this.SelectedItem = null;
    });

    this.InfoWindow.addListener('domready', () => {
      const infoWindowButton = document.getElementById(`infoWindowButton`);
      if (infoWindowButton) {
        infoWindowButton.addEventListener('click', () => {
          this.Redirect(item.Reroute);
        });
      }
    });
  }

  public ToggleLayer(evt: any, type: string, SubItemType: string | null){
    if(evt.target.checked){
      let layer = AvailableLayers.filter(x => x.Type == type && x.SubItemTypes == SubItemType);
      if(layer){
        this.Layers = this.Layers.concat(layer);
      }
      this.SearchForItems();
    }
    else{
      this.Layers = this.Layers.filter(x => x.Type != type)
      this.SearchForItems();
    }
  }

  public IsItemInLayers(type: string, SubItemType: string | null){
    if(this.Layers.some(x => x.Type == type && x.SubItemTypes == SubItemType)){
      return true;
    }
    return false;
  }
}


