import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceBase } from './api.service.base';
import { ServiceBusClient} from '@azure/service-bus'
import { OrganizationsService } from './organizations.service';
import { PermissionsService } from './permissions.service';
import { LoginServiceBase } from '../auth/login.service.base';
import { OccupancyTotalsResponse } from './occupancy.service';

export interface ViolationUpdate {
  ViolationStatus: string;
  ParkingSpaceName: string;
  StartDateLocalFriendlyString: string;
  ActionedDateLocalFriendlyString: string | null;
  EndDateLocalFriendlyString: string | null;
  ParkingStartDateLocalFriendlyString: string | null;
  ParkingSpaceId: number | null;
  ParkingSessionId: number | null;
  TicketReference: string;
  PlateNumber: string;
  Notes: string;
  ActionedByUserId: string | null;
  Id: number;
  Selected?: boolean;
}

interface ViolationDelete {
  Id: number;
}

@Injectable({
  providedIn: 'root'
})
export class EnforcementService {

  public ViolationsChanged = new EventEmitter<ViolationUpdate[]>(); // Add this line
  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;
  public Violations: ViolationUpdate[] = [];

  constructor(private apiService : ApiServiceBase, private organizationsService : OrganizationsService, private loginService : LoginServiceBase, private permissionService : PermissionsService) { 
    this.fetchViolations();
    organizationsService.AvailableOrganizationsChanged.subscribe(result =>{
      this.ConnectServiceBus(); //connect to the bus if we aren't already connected
    });
    loginService.OrganizationChanged.subscribe(result => {
      this.ConnectServiceBus(); //disconnect from previous bus and connect to new org
    });
    this.ConnectServiceBus();
  }

  fetchViolations(){
    this.Violations = [];
    this.apiService.Get<any>("enforcement/violations/current" ).then(result => {
      this.Violations = result;
      this.Violations.sort((a, b) => {
        // Convert time strings to Date objects by adding a dummy date
        const dateA = new Date(`1970/01/01 ${a.StartDateLocalFriendlyString}`);
        const dateB = new Date(`1970/01/01 ${b.StartDateLocalFriendlyString}`);
        
        // Fallback if dates are invalid
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          console.warn('Invalid time found:', { a: a.StartDateLocalFriendlyString, b: b.StartDateLocalFriendlyString });
          return 0;
        }
        
        return dateB.getTime() - dateA.getTime();
      });

      this.ViolationsChanged.emit(this.Violations);
    });
  }

  handleViolationUpdate(message: string) {
    try {
      const violation = JSON.parse(message) as ViolationUpdate;
      console.log('New violation:', violation);
      console.log('Existing violations:', this.Violations);
      
      // Don't add violations that are already ended
      if (violation.EndDateLocalFriendlyString) {
        return;
      }
      
      // Update the violations list
      const existingIndex = this.Violations.findIndex((v) => {
        console.log('Comparing:', v.Id, violation.Id);
        return v.Id === violation.Id;
      });

      console.log('Found at index:', existingIndex);

      if (existingIndex !== -1) {
        // Update existing violation
        this.Violations[existingIndex] = violation;
      } else {
        // Add new violation
        this.Violations.push(violation);
      }

      this.Violations.sort((a, b) => {
        // Convert time strings to Date objects by adding a dummy date
        const dateA = new Date(`1970/01/01 ${a.StartDateLocalFriendlyString}`);
        const dateB = new Date(`1970/01/01 ${b.StartDateLocalFriendlyString}`);
        
        // Fallback if dates are invalid
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          console.warn('Invalid time found:', { a: a.StartDateLocalFriendlyString, b: b.StartDateLocalFriendlyString });
          return 0;
        }
        
        return dateB.getTime() - dateA.getTime();
      });

      this.ViolationsChanged.emit(this.Violations);

    } catch (error) {
      console.error('Error parsing violation update:', error);
    }
  }
  
  handleViolationDelete(message: string) {
    try {
      const deleteInfo = JSON.parse(message) as ViolationDelete;
      // Remove the violation from the list
      this.Violations = this.Violations.filter((v) => v.Id !== deleteInfo.Id);
      this.ViolationsChanged.emit(this.Violations);
    } catch (error) {
      console.error('Error parsing violation deletion:', error);
    }
  }

  getViolationStatusColor(status: string): string {
    switch(status) {
      case 'Pending':
        return '#D32F2F';  // Keep bright red for pending violations
      case 'Actioned':
        return '#9E9E9E';  // Grey for actioned violations
      case 'Actioned and Vacated':
        return '#BDBDBD';  // Light grey for actioned and vacated
      case 'Vacated without Action':
        return '#E0E0E0';  // Very light grey for vacated without action
      default:
        return '#000000';
    }
  }

  getViolationStatuses(): string[] {
    return ['Pending', 'Actioned', 'Actioned and Vacated', 'Vacated without Action'];
  }

  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }
  
     this.apiService.Post<any>("enforcement/servicebus/topics/Enforcement/" + this.loginService.UserId() + this.permissionService.GetSessionIdentifier(), {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("Enforcement", this.loginService.UserId() + this.permissionService.GetSessionIdentifier());
      const SBMessageHandler = async (messageReceived:any) => {
        const messageType = messageReceived.applicationProperties?.MessageType;
        const messageBody = messageReceived.body;

        switch (messageType) {
          case 'ViolationCreated':
            this.handleViolationUpdate(JSON.stringify(messageBody));
            break;
          case 'ViolationDeleted':
            this.handleViolationDelete(JSON.stringify(messageBody));
            break;
          case 'ViolationUpdated':
            this.handleViolationUpdate(JSON.stringify(messageBody));
            break;
        }
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }

  public RemoveViolation(violationId: number) {
    // Remove from local array
    const index = this.Violations.findIndex(v => v.Id === violationId);
    if (index > -1) {
      this.Violations.splice(index, 1);
      // Emit change event
      this.ViolationsChanged.emit(this.Violations);
    }
  }

  public RefetchData() {
    this.fetchViolations();
    this.ConnectServiceBus();
  }
}
