import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { FeaturesService } from 'src/app/Services/features.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { EditsessionratesetmodalComponent } from './editsessionratesetmodal.component';
import { Busyable } from 'src/app/shared/editors/busyable';
import { catchError, ObservableInput, of } from "rxjs";

@Component({
    selector: 'app-editsessionrateset',
    templateUrl: './editsessionrateset.component.html',
    styleUrls: ['./editsessionrateset.component.scss']
  })
  export class EditsessionratesetComponent implements OnInit {

    public RateSets: any[] = [];
    @Input()
    public Session: any = null;
    
    public RateSetId: any = null;

    public Animate : boolean = false;

    constructor(private modalService: SimpleModalService, private route: ActivatedRoute, private apiService: ApiServiceBase, private organizationsService: OrganizationsService, private toastService: ToastrService, private _parent: EditsessionratesetmodalComponent) {
      apiService.Get<any>("parking/ratesets").then(result => {
        this.RateSets = result;
      });
    }

    public ngOnInit(): void {
      this.RateSetId = this.Session.ParkingRateSetId;
    }

    public DefaultModel() {

    }

    public AfterModelLoaded(): void {

    }

    public BeforeSave(): void {

    }

    public Validators() {

    }  
    private HandleApiError(err: any): ObservableInput<any> {
      if (err.error != null && err.error.Error != null) {
          this.toastService.error(err.error.Error.Message, err.error.Error.ErrorType);
      }
      else if (err.error && err.error.errors && err.error.errors.id) {
          this.toastService.error(err.error.errors.id[0], err.error.title);
      }
      else {
          this.toastService.error('There was a problem', 'Error');
      }
      return of(null);
  }

    public confirm() {
      this.Animate = true;
      let url = "parking/parkingsessions";
      var submitModel = {ParkingRateSetId: this.RateSetId, Id: this.Session.Id };
      this.apiService.Post(url, submitModel).then(result => {
        this.Animate = false;
          if (result != null) {
              this.toastService.success('Item updated successfully', 'Saved');
              this._parent.close();
          }
      });
    }

    public close() {
      this._parent.close();
    }
  }