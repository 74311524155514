<app-pageheader [Parent]="this">
</app-pageheader>
 <div style="height:4em">
   <p class="is-size-3" style="display: flex; align-items: center; justify-content: center; font-weight: bold; color: green; font-family: FrogFont;">Enforcement</p>
</div>
<div class="columns">
   <div class="column is-10 is-offset-1">
      <div class="columns" style="min-height:50%; margin-top: 0;">
         <div class="column is-6" id="leftColumn" #leftColumn>
            <div class="card">
               <div class="columns" style="height:100%; margin-top: 0;">
                  <div class="column is-8 is-offset-2">
                     <app-loader [Parent]="this"></app-loader>
                     <div class="" style="display:flex; align-items: center; justify-content: center;">
                        <div class="percentage" style="height:50%">
                           <app-percentagechart [Percent]="Percent" [Height]="200" [Color]="'red'" ></app-percentagechart>
                          </div>
                        <div>
                        <div class="white-bg" style="height:100%; margin-left:2em;">
                           <p class="text is-size-3">Violations</p>
                        </div>
                        </div>
                     </div>
                     <div class=""  style="display:flex; align-items: center; justify-content: center; cursor:pointer" routerLink="violations">
                      <div class="card-stat p-1">
                         <div class="red-bg p-1 tag is-large is-rounded">
                            <p class="numbers">{{enforcementService.Violations.length}} / {{OccupiedSpaces}}</p>
                         </div>
                      </div>
                      <div class="white-bg" style="height:100%; margin-left:2em;">
                            <p class="text is-size-5">Currently Violating</p>
                      </div>
                   </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="column is-6" *ngIf="rightColumnheight != 0" [ngStyle]="{'height': (rightColumnheight != null ? rightColumnheight : 0) + 'px'}">
            <div class="card" style="height:100%">
               <app-loader [Parent]="this"></app-loader>
               <div style="width:100%;">
                  <p class="pt-4 is-size-4" style="font-weight: 500; text-align: center;">Alerts</p>
               </div>
               <div class="p-4" style="height:100%">
                  <div *ngIf="showAlerts" class="p-4" style="width: 100%; height: 80%; display: flex; align-items: center; justify-content: center;">
                     <div>
                        <div *ngIf="alerts.length > 0">
                           <div class="alertitem" *ngFor="let alert of alerts"><i class="fa fa-{{alert.icon}} alerticon" [ngStyle]="{'color': alert.iconcolor}"></i><p class="alerttext">{{alert.text}}</p></div>
                        </div>
                        <div *ngIf="alerts.length === 0" class="alertitem">
                           <i class="fa fa-check alerticon" style="color:green"></i>
                           <p class="alerttext" style="color:green; font-weight: 500;">You have no alerts</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="columns">
         <div class="column is-4" *ngFor="let chart of charts">
            <div style="position: relative;" (click)="openFullPageStats(chart)" style="cursor: pointer;">
              <app-dashboard-chart 
              [chartType]="chart.chartType"
              [stacked]="chart.stacked"
              [height]="350"
              [yAxisLabel]="chart.yAxisLabel"
              [dataSetRequests]="chart.dataSetRequests"
              [title]="chart.title"
              [ShowFilters]="false"
              [isFullPage]="false"
              [selectedDateRange]="chart.selectedDateRange"
              [GroupBy]="chart.groupBy"
              [bucketSize]="chart.bucketSize"
              [asPercentage]="chart.asPercentage">
            </app-dashboard-chart>
            </div>
          </div>
    </div>
   </div>
</div>