import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, OnDestroy, HostListener } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker, MapPolygon } from '@angular/google-maps';
import { LotmultiviewerComponent } from 'src/app/components/lotmultiviewer/lotmultiviewer.component';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { Busyable } from 'src/app/shared/editors/busyable';
import { Chart } from 'chart.js';
import { EnforcementService } from 'src/app/Services/enforcement.service';
import { GuidanceFeedService } from 'src/app/Services/guidancefeed.service';
import { ActionviolationmodalComponent } from '../modals/actionviolation/actionviolationmodal.component';
import { SimpleModalService } from 'ngx-simple-modal';

interface CustomPolygonOptions extends google.maps.PolygonOptions {
  spaceId?: number;
  spaceGuid?: string;
  center?: google.maps.LatLngLiteral;
  polygon?: google.maps.Polygon;
}

@Component({
  selector: 'app-enforcementmap',
  templateUrl: './enforcementmap.component.html',
  styleUrls: ['./enforcementmap.component.scss']
})


export class EnforcementMapComponent extends Busyable implements OnInit, OnDestroy {

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12}; // Set your default center
  parkingSpacePolygons: CustomPolygonOptions[] = [];
  parkingSpaces: any[] = [];
  zoom = 19; 
  ParkingLots: any[] = [];
  ParkingLevels: any[] = [];
  Violations: any[] = [];
  SelectedParkingLot: any;
  ParkingLotResponse: any;
  public Busyable: Busyable = new Busyable();
  @ViewChild(GoogleMap) map!: GoogleMap;
  selectedTimeRange: '1hour' | 'today' = '1hour';
  private parkingStatsChart: Chart<'doughnut', number[], unknown> | null = null;
  selectedMarker: google.maps.Marker | null = null;
  selectedViolationId: number | null = null;
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  private currentInfoWindow: google.maps.InfoWindow | null = null;
  private guidanceFeedSubscription: any;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private featuresService: FeaturesService, 
    private cdr: ChangeDetectorRef,
    public apiService: ApiService, 
    public enforcementService: EnforcementService, 
    public occupancyService: OccupancyService,
    private guidanceFeedService: GuidanceFeedService,
    private modalService: SimpleModalService
  ) { 
    super();

    this.enforcementService.ViolationsChanged.subscribe(violations => {
      this.parkingSpaces.forEach(space => {
        const violation = violations.find(v => v.ParkingSpaceId === space.IId);
        space.Violation = violation || null;
        this.updateSpaceDisplay(space);
      });
      // Update chart data when violations change
      if (this.parkingStatsChart && this.SelectedParkingLot) {
        const data = [
          ((this.SelectedParkingLot.TotalSpaces - this.SelectedParkingLot.AvailableSpaces) - violations.length) / this.SelectedParkingLot.TotalSpaces * 100,
          (this.SelectedParkingLot.AvailableSpaces / this.SelectedParkingLot.TotalSpaces * 100),
          (violations.length / this.SelectedParkingLot.TotalSpaces * 100)
        ];
        this.parkingStatsChart.data.datasets[0].data = data;
        this.parkingStatsChart.update();
      }
    });

    this.occupancyService.OccupancyChanged.subscribe(occupancy => {
      if (this.SelectedParkingLot) {
        this.occupancyService.GetOccupancy(this.SelectedParkingLot.Id).then((result: any) => {
          this.SelectedParkingLot.TotalSpaces = result.TotalSpaces;
          this.SelectedParkingLot.OccupiedSpaces = result.OccupiedSpaces;
          this.SelectedParkingLot.AvailableSpaces = result.AvailableSpaces;
          this.createParkingStatsChart();
        });
      }
    });

    // Subscribe to guidance feed updates
    this.guidanceFeedService.GuidanceFeedServiceUpdated.subscribe(val => {
      if (val != null && this.parkingSpaces) {
        const spaceId = val.SpaceId;
        const messageType = val.MessageType;
        const space = this.parkingSpaces.find(ps => ps.Id === spaceId);

        if (space) {
          const violation = this.enforcementService.Violations.find(v => v.ParkingSpaceId === space.IId);
          switch (messageType) {
            case 'CarOnEvent':
              if (val.Body?.OccupiedOn) {
                space.OccupiedSince = val.Body.OccupiedOn;
              } else {
                space.OccupiedSince = val.EnqueuedTimeUtc;
              }
              if (val.Body?.OccupiedSinceLocalFriendlyString) {
                space.OccupiedSinceLocalFriendlyString = val.Body.OccupiedSinceLocalFriendlyString;
              } else {
                space.OccupiedSinceLocalFriendlyString = new Date(space.OccupiedSince).toLocaleString(undefined, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                });
              }
              space.Violation = null;
              this.updateSpaceDisplay(space);
              break;
            case 'CarOffEvent':
              space.OccupiedSince = null;
              space.OccupiedSinceLocalFriendlyString = null;
              space.Violation = null;
              this.updateSpaceDisplay(space);
              break;
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.fetchParkingLots();
  };

  @HostListener("document:visibilitychange", ['$event'])
  handlePageVisibilityChange() {
    if (document.hidden){
      console.log("Page hidden")
    }
    else {
      console.log("Page focused - attempting to resume enforcement data")
      this.enforcementService.RefetchData();
    }
  }

  onParkingLotChange(event: any) {
    this.SelectedParkingLot = this.ParkingLots.find(lot => lot.Id === event.target.value);
    this.fetchParkingLot();
  }

  fetchParkingLot(){
    this.apiService.Get<any>("enforcement/parkinglots/" + this.SelectedParkingLot.Id).then(result => {
      this.ParkingLotResponse = result;
      this.createParkingStatsChart()
      this.loadParkingSpaces();
    });
  }
  
  loadParkingSpaces() {
    // Clear existing polygons from the map AND empty the array
    this.parkingSpacePolygons.forEach(p => {
      if (p.polygon) {
        p.polygon.setMap(null);  // Remove from map
      }
    });
    this.parkingSpacePolygons = [];  // Clear array

    if(this.ParkingLotResponse == null) return;
    this.parkingSpaces = [];  // Clear parking spaces array too

    this.ParkingLotResponse.Levels.forEach((level : any) => {
      level.Rows.forEach((row: any) => {
        if (row.Spaces) {
          this.parkingSpaces.push(...row.Spaces);

          row.Spaces.forEach((space: any) => {


            const v = this.enforcementService.Violations.find(violation => violation.ParkingSpaceId === space.IId);
            if(v){
              space.Violation = v;
            }
            

            if (space.GeoPolygonPoints && space.GeoPolygonPoints.length > 0) {
              const polygonPath = space.GeoPolygonPoints.map((coord: any) => ({
                lat: coord[1],
                lng: coord[0]
              }));

              var defaultColor = '#000000';  // Black for unoccupied spaces

              if(space.OccupiedSince) {
                if(space.Violation != null) {
                  defaultColor = this.enforcementService.getViolationStatusColor(space.Violation.ViolationStatus);
                } else {
                  defaultColor = '#2E8B57';
                }
              }

              // Create polygon options
              const polygonOptions: CustomPolygonOptions = {
                paths: polygonPath,
                strokeColor: defaultColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: defaultColor,
                fillOpacity: 0.35,
                clickable: true,
                draggable: false,
                spaceId: space.IId,
                spaceGuid: space.Id,
                center: space.GeoLocation ? {lat: space.GeoLocation[1], lng: space.GeoLocation[0]} : undefined
              };

              // Create single polygon instance
              const polygon = new google.maps.Polygon({
                ...polygonOptions,
                map: this.map.googleMap
              });

              // Add click handler
              polygon.addListener('click', () => {
                this.onSpaceClick({
                  spaceId: space.IId,
                  spaceGuid: space.Id,
                  center: space.GeoLocation ? {lat: space.GeoLocation[1], lng: space.GeoLocation[0]} : undefined
                });
              });

              // Store the polygon instance with the options
              polygonOptions.polygon = polygon;
              this.parkingSpacePolygons.push(polygonOptions);
            }
          });
        }
      });
    });


    this.map?.panTo({
      lat: this.ParkingLotResponse.GeoPolygonCenter[1],
      lng: this.ParkingLotResponse.GeoPolygonCenter[0]
    });

    this.zoom = 20; // Adjust zoom level for parking spaces
  }

  hasMatchingParkingSpace(violation: any): boolean {
    return this.parkingSpaces.some((space: any) => space.IId === violation.parkingSpaceId);
  }

  fetchParkingLots() {
    this.Busyable.Loading();
    this.apiService.Get<any>("infrastructure/parkinglots").then(result => {
      this.ParkingLots = result;
      this.onParkingLotChange({target: {value: this.ParkingLots[0].Id}});
      this.Busyable.StopLoading();
    });
  }

  panToViolation(violation: any) {
    var space = this.parkingSpacePolygons.find(space => space.spaceId === violation.ParkingSpaceId);
    if(space && space.center){
      this.map.panTo({
        lat: space.center.lat,
        lng: space.center.lng
      });
      
      this.zoom = 20;
      
      // Clear previous selections
      this.enforcementService.Violations.forEach(v => v.Selected = false);
      
      // Set only this violation as selected
      violation.Selected = true;

      // Add force change detection here
      this.cdr.detectChanges();

      // Update scrolling behavior with debugging
      setTimeout(() => {
        if (this.scrollContainer?.nativeElement) {
          console.log('Looking for violation card...');
          const allCards = this.scrollContainer.nativeElement.querySelectorAll('.violation-card');
          console.log('Found violation cards:', allCards.length);
          
          const selectedElement = this.scrollContainer.nativeElement.querySelector('.violation-card.selected');
          console.log('Selected element found:', !!selectedElement);
          
          if (selectedElement) {
            selectedElement.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center'
            });
          }
        }
      }, 100);

      // Update marker
      if (this.selectedMarker) {
        this.selectedMarker.setMap(null);
      }
      if (space != null && space.center) {
        const parkingSpace = this.parkingSpaces.find(ps => ps.IId == space?.spaceId);
        const spaceName = parkingSpace?.Name || 'Space';

        // Create and update info window
        if (parkingSpace) {
          let content = document.createElement('div');
          content.innerHTML = `
            <div style="padding: 8px;">
              <h3 style="margin: 0 0 8px 0; font-size: 14px;">Space ${parkingSpace.Name}</h3>
              ${parkingSpace.OccupiedSince ? 
                `<p style="margin: 0; font-size: 12px;">
                  Occupied since: ${parkingSpace.OccupiedSinceLocalFriendlyString}
                </p>` : 
                '<p style="margin: 0; font-size: 12px;">Space is vacant</p>'
              }
              ${parkingSpace.Violation ? 
                `<p style="margin: 4px 0 0 0; font-size: 12px; color: #d32f2f;">
                  Violation since: ${parkingSpace.Violation.StartDateLocalFriendlyString}
                </p>` : 
                ''
              }
            </div>
          `;

          // Close existing info window
          if (this.currentInfoWindow) {
            this.currentInfoWindow.close();
          }

          // Create and show new info window
          this.currentInfoWindow = new google.maps.InfoWindow({
            content: content,
            position: space.center
          });
          this.currentInfoWindow.open(this.map.googleMap);
        }

        // Create marker
        this.selectedMarker = new google.maps.Marker({
          position: space.center,
          map: this.map.googleMap,
          label: {
            text: spaceName,
            color: '#ffffff',
            fontSize: '14px'
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 15,
            fillColor: '#4CAF50',
            fillOpacity: 1,
            strokeColor: '#ffffff',
            strokeWeight: 2
          }
        });
      }
    }
  }
  
  private createParkingStatsChart() {
    const ctx = document.getElementById('parkingStats') as HTMLCanvasElement;

    const data = this.SelectedParkingLot != null ? [
        ((this.SelectedParkingLot.TotalSpaces - this.SelectedParkingLot.AvailableSpaces) - this.enforcementService.Violations.length) / this.SelectedParkingLot.TotalSpaces * 100 ?? 0,
        (this.SelectedParkingLot.AvailableSpaces / this.SelectedParkingLot.TotalSpaces * 100) ?? 0,
        (this.enforcementService.Violations.length / this.SelectedParkingLot.TotalSpaces * 100) ?? 0
    ] : [];

    if (this.parkingStatsChart) {
        this.parkingStatsChart.data.datasets[0].data = data;
        this.parkingStatsChart.update(); // Use 'none' mode for smoother updates
    } else {
        // Store the reference to totalSpaces in a variable that can be accessed by the plugin        
        this.parkingStatsChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ['Occupied', 'Available', 'Violation'],
                datasets: [{
                    data: data,
                    backgroundColor: [
                        '#2E8637',
                        '#505050',
                        '#d32f2f'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                cutout: '60%',
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                events: []
            },
            plugins: [{
                id: 'doughnutLabel',
                beforeDraw(chart: any) {
                    const { ctx, width, height } = chart;
                    const data = chart.data.datasets[0].data;
                    // Calculate total from current data (occupied + violations)
                    const total = Math.round(data[0] + data[2]);
                    
                    ctx.save();
                    ctx.font = '16px Arial';
                    ctx.fillStyle = '#000';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fontWeight = 'bold';
                    ctx.fillText(`${total}%`, width / 2, height / 2);
                    ctx.restore();
                }
            }]
        });
    }
  }

  onSpaceClick(space: CustomPolygonOptions) {
    if (!space || !space.spaceId) {
      return;
    }

    // Clear previous selections
    this.enforcementService.Violations.forEach(v => v.Selected = false);

    // Find the actual parking space data
    const parkingSpace = this.parkingSpaces.find(ps => ps.IId === space.spaceId);
    if (!parkingSpace) return;

    // Create info window content
    let content = document.createElement('div');
    content.innerHTML = `
      <div style="padding: 8px;">
        <h3 style="margin: 0 0 8px 0; font-size: 14px;">Space ${parkingSpace.Name}</h3>
        ${parkingSpace.OccupiedSinceLocalFriendlyString ? 
          `<p style="margin: 0; font-size: 12px;">
            Occupied since: ${parkingSpace.OccupiedSinceLocalFriendlyString}
          </p>` : 
          '<p style="margin: 0; font-size: 12px;">Space is vacant</p>'
        }
        ${parkingSpace.Violation ? 
          `<p style="margin: 4px 0 0 0; font-size: 12px; color: #d32f2f;">
            Violation since: ${parkingSpace.Violation.StartDateLocalFriendlyString}
          </p>` : 
          ''
        }
      </div>
    `;

    // Create and show the info window
    if (space.center) {
      const infoWindow = new google.maps.InfoWindow({
        content: content,
        position: space.center
      });

      // Close any existing info windows
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close();
      }
      this.currentInfoWindow = infoWindow;
      infoWindow.open(this.map.googleMap);
    }

    // Handle violation selection (existing code)
    const violation = this.enforcementService.Violations.find(
      v => v.ParkingSpaceId === space.spaceId
    );
    
    if (!violation) {
      if (this.selectedMarker) {
        this.selectedMarker.setMap(null);
      }
    }
    else {
      violation.Selected = true;
    }
    
    this.cdr.detectChanges();

    setTimeout(() => {
      if (this.scrollContainer?.nativeElement) {
        console.log('Looking for violation card...');
        const allCards = this.scrollContainer.nativeElement.querySelectorAll('.violation-card');
        console.log('Found violation cards:', allCards.length);
        
        const selectedElement = this.scrollContainer.nativeElement.querySelector('.violation-card.violation-selected');
        console.log('Selected element found:', !!selectedElement);
        
        if (selectedElement) {
          selectedElement.scrollIntoView({ 
            behavior: 'smooth', 
            block: 'center'
          });
        }
      }
    }, 100);

    // Update marker (existing code)
    if (this.selectedMarker) {
      this.selectedMarker.setMap(null);
    }

    console.log(space.center);
    if (space.center) {
      const parkingSpace = this.parkingSpaces.find(ps => ps.IId === space.spaceId);
      const spaceName = parkingSpace?.Name || 'Selected Space';

      this.selectedMarker = new google.maps.Marker({
        position: space.center,
        map: this.map.googleMap,
        label: {
          text: spaceName,
          color: '#ffffff',
          fontSize: '14px'
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 15,
          fillColor: '#4CAF50',
          fillOpacity: 1,
          strokeColor: '#ffffff',
          strokeWeight: 2
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
    if (this.guidanceFeedSubscription) {
      this.guidanceFeedSubscription.unsubscribe();
    }
  }

  // Add helper method to update space display
  private updateSpaceDisplay(space: any) {
    const polygonData = this.parkingSpacePolygons.find(p => 
      p.spaceGuid === space.Id || p.spaceId === space.IId
    );
    
    if (polygonData && polygonData.polygon) {
      let newColor = '#000000';  // Default black for unoccupied

      if (space.OccupiedSince) {
        if (space.Violation) {
          newColor = this.enforcementService.getViolationStatusColor(space.Violation.ViolationStatus);
        } else {
          newColor = '#2E8B57';  // Green for occupied without violation
        }
      }

      // Just update the color properties
      polygonData.polygon.setOptions({
        strokeColor: newColor,
        fillColor: newColor
      });

      // Debug output
      console.log(`Updated space ${space.Name} (${space.Id}) to color ${newColor}`);

      // Update info window if needed
      this.updateInfoWindowIfOpen(space, polygonData);
    }
  }

  // Add this helper method to handle info window updates
  private updateInfoWindowIfOpen(space: any, polygonData: CustomPolygonOptions) {
    const markerPosition = this.selectedMarker?.getPosition();
    const polygonCenter = polygonData.center;
    
    if (this.currentInfoWindow && markerPosition && polygonCenter && 
        markerPosition.lat() === polygonCenter.lat && 
        markerPosition.lng() === polygonCenter.lng) {
      let content = document.createElement('div');
      var occSinceString = space.OccupiedSinceLocalFriendlyString ?? new Date(space.OccupiedSince).toLocaleString();
      content.innerHTML = `
        <div style="padding: 8px;">
          <h3 style="margin: 0 0 8px 0; font-size: 14px;">Space ${space.Name}</h3>
          ${space.OccupiedSince ? 
            `<p style="margin: 0; font-size: 12px;">
              Occupied since: ${occSinceString}
            </p>` : 
            '<p style="margin: 0; font-size: 12px;">Space is vacant</p>'
          }
          ${space.Violation ? 
            `<p style="margin: 4px 0 0 0; font-size: 12px; color: #d32f2f;">
              Violation since: ${space.Violation.StartDateLocalFriendlyString}
            </p>` : 
            ''
          }
        </div>
      `;
      this.currentInfoWindow.setContent(content);
    }
  }

  public openActionViolationModal(violation: any) {
    this.modalService.addModal(ActionviolationmodalComponent, {
      violation: violation
    });
  }
}
