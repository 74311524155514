<section class="modal-card-body"style="min-width: 800px;">
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Rate Set</label>
        </div>
        <div class="field-body">
            <div class="field">
            <p class="select">
                  <select class="input" [(ngModel)]="RateSetId">
                      <option *ngFor="let rateSet of RateSets" [ngValue]="rateSet.Id">{{rateSet.Name}}</option>
                  </select>
            </p>
            </div>
        </div>  
    </div>
</section>
<footer class="modal-card-foot">
    <button class="button has-background-white mr-2" (click)="close()"><span class="icon"><i class="fa fa-xmark"></i></span><span>Cancel</span></button>
    <app-modal-save-button (OnSave)="confirm()"></app-modal-save-button>
</footer>