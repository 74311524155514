<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">Space Configuration</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Id" class="control">
                            <input formControlName="IId" class="input mb-3" type="text" readonly/>
                            <app-validator [For]="this.Form.get('IId')"></app-validator>
                        </app-field>
                        <app-field Label="Location" class="control">
                            <input formControlName="LocationString" class="input mb-3" type="text" readonly/>
                            <app-validator [For]="this.Form.get('LocationString')"></app-validator>
                        </app-field>
                        <app-field Label="Name" class="control">
                            <input formControlName="Name" class="input mb-3" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </app-field>
                        <app-field Label="Sleep Time" class="control">
                            <input formControlName="SleepTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('SleepTime')"></app-validator>
                        </app-field>
                        <app-field Label="Wake Up Time" class="control">
                            <input formControlName="WakeUpTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('WakeUpTime')"></app-validator>
                        </app-field>
                        <app-field Label="Start of Day Time" class="control">
                            <input formControlName="StartOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('StartOfDayTime')"></app-validator>
                        </app-field>
                        <app-field Label="End of Day Time" class="control">
                            <input formControlName="EndOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('EndOfDayTime')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">Device Settings</p>
                <div class="panel-block">
                    <div formGroupName="Device" class="three-quarters-width">
                        <app-field Label="Hardware Version" class="control">
                            <input class="input mb-3" formControlName="HardwareVersionVersion" type="number" readonly/>
                        </app-field>
                        <app-field Label="Firmware Version" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="NewFirmwareVersion">
                                    <option *ngFor="let item of FirmwareVersions" [ngValue]="item.Version">{{item.Version}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Extended Configuration" class="control">
                            <input formControlName="ExtendedConfiguration" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('ExtendedConfiguration')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
    </div>
</div>
