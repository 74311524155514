import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker, MapPolygon } from '@angular/google-maps';
import { Router } from '@angular/router';
import { LotmultiviewerComponent } from 'src/app/components/lotmultiviewer/lotmultiviewer.component';
import { ApiService } from 'src/app/Services/api.service';
import { EnforcementService } from 'src/app/Services/enforcement.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { ChartConfiguration, DateRangeOption, GroupByOption } from 'src/app/shared/charts/dashboardchart/ChartConfiguration';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-enforcementhome',
  templateUrl: './enforcementhome.component.html',
  styleUrls: ['./enforcementhome.component.scss']
})


export class EnforcementHomeComponent extends Busyable implements OnInit {

  public alerts: any = [];
  public Lots: any = [];

  public Summary : any | null = null;

  public TotalSpaces: number = 0;
  public OccupiedSpaces: number = 0;
  public Percent: number = 0;
  public rightColumnheight: number = 0;

  @ViewChild("leftColumn")
  public leftColumn!: ElementRef;

  public occupancyService: OccupancyService;
  public apiService: ApiService;

  OccupancySubscription: any;
  public cdr: ChangeDetectorRef;
  public showAlerts: boolean = false;

  public charts: ChartConfiguration[] = [
      {
        chartType: 'bar',
        stacked: false,
        dataSetRequests: [{
            ChartType: 'bar',
            ReportDateGrouping: 'StartDate',
            TargetType: 'Violation',
            Label: 'Violations',
            AggregateType: 'Count',
            Metric: 'ModelId',
            DisplayColor: '#006400'
          }
        ],
        title: 'Violations Today',
        yAxisLabel: 'Number of Violations',
        showFilters: true,
        isFullPage: true,
        groupBy: 'h' as GroupByOption,
        asPercentage: false,
        selectedDateRange: 'today' as DateRangeOption,
        hideStatistics: false
      },
      {
        chartType: 'bar',
        stacked: false,
        dataSetRequests: [{
            ChartType: 'bar',
            TargetType: 'Violation',
            Label: 'Violations',
            ReportDateGrouping: 'StartDate',
            AggregateType: 'Count',
            Metric: 'ModelId',
            DisplayColor: '#006400'
            }
        ],
        title: 'Violations This Week',
        yAxisLabel: 'Number of Violations',
        showFilters: true,
        isFullPage: true,
        groupBy: 'd' as GroupByOption,
        asPercentage: true,
        selectedDateRange: 'thisWeek' as DateRangeOption,
        hideStatistics: false
      },
      {
        chartType: 'bar',
        stacked: false,
        dataSetRequests: [{
          ChartType: 'bar',
          ReportDateGrouping: 'Concurrent',
          TargetType: 'Violation',
          Label: 'Violations',
          AggregateType: 'Count',
          Metric: 'Duration',
          DisplayColor: '#006400',
          Filters: [
            {
              Property: 'Duration',
              Operator: '<=',
              Value: "1440"
            }
          ]
        }],
        title: 'Average Duration (This Week)',
        yAxisLabel: 'Count per Bucket',
        showFilters: true,
        isFullPage: true,
        groupBy: 'metric' as GroupByOption,
        bucketSize: 60,
        asPercentage: false,
        selectedDateRange: 'thisWeek' as DateRangeOption,
        hideStatistics: true
      }
    ];

  constructor(private featuresService: FeaturesService, 
    occupancyService: OccupancyService, 
    cdr: ChangeDetectorRef, 
    apiservice: ApiService,
    public enforcementService: EnforcementService, public router : Router) { 
    super();
    console.log("EnforcementHome Constructor");
    this.occupancyService = occupancyService;
    this.cdr = cdr;
    this.apiService = apiservice;

    this.OccupancySubscription = occupancyService.OccupancyChanged.subscribe((val: any) => {
      this.RefreshOccupancy();
    });

  }

  ngOnInit(): void {
    this.Loading();
    this.RefreshOccupancy();

    this.OccupancySubscription = this.occupancyService.OccupancyChanged.subscribe(result =>{
      this.showAlerts = true;
      for(let lot of this.Lots){
        this.occupancyService.GetOccupancy(lot.Id).then((x: any) => {
          lot.TotalSpaces = x.TotalSpaces;
          lot.OccupiedSpaces = (x.TotalSpaces as number) - (x.AvailableSpaces as number);
          lot.AvailableSpaces = x.AvailableSpaces;
        });
        this.UpdateColumnHeight();
      }
    });

    // this.alerts.push({icon: "car", iconcolor: "red", text: "You are in trouble"});
    // this.alerts.push({icon: "thumbs-down", iconcolor: "red", text: "Oh no, something broke"});
    // this.alerts.push({icon: "gear", iconcolor: "orange", text: "We are having problems"});
    // this.alerts.push({icon: "wifi", iconcolor: "red", text: "Connection issues"});
    // this.alerts.push({icon: "users", iconcolor: "dark-yellow", text: "Users have broken something"});
  }

  private RefreshOccupancy(){
    this.occupancyService.GetOccupancy(null).then((occupancy: any) => {
      if(occupancy != null){
        this.TotalSpaces = occupancy.TotalSpaces;
        this.OccupiedSpaces = (occupancy.TotalSpaces as number) - (occupancy.AvailableSpaces as number);
        // Calculate percentage of violations relative to occupied spaces
        this.Percent = this.OccupiedSpaces > 0 ? 
          (this.enforcementService.Violations.length / this.OccupiedSpaces) * 100 : 0;
        // Update precision based on value
        this.Percent = this.Percent < 1 ? 
          parseFloat(this.Percent.toFixed(2)) : 
          parseFloat(this.Percent.toFixed(0));
        this.UpdateColumnHeight();
      }
      this.StopLoading();
      this.showAlerts = true;
    });
  }

  openFullPageStats(chart: ChartConfiguration) {
    this.router.navigate(['/parking/parking/statistics/full'], {
      state: {
        chartType: chart.chartType,
        stacked: chart.stacked,
        selectedDateRange: chart.selectedDateRange,
        title: chart.title,
        dataSetRequests: chart.dataSetRequests,
        isFullPage: chart.isFullPage,
        groupBy: chart.groupBy,
        bucketSize: chart.bucketSize,
        asPercentage: chart.asPercentage,
        hideStatistics: chart.hideStatistics
      }
    }).then(() => {
      // Save the state to localStorage as a backup
      localStorage.setItem('dashboard-chart-config', JSON.stringify(chart));
    });
  }


  public UpdateColumnHeight(){
    if (this.leftColumn) {
      this.rightColumnheight = this.leftColumn.nativeElement.offsetHeight;
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.apiService.Get<any>("infrastructure/parkinglots/geometry").then((results: any) => {
      for(let result of results){
          this.Lots.push(result);
          this.UpdateColumnHeight();
      }
    });
  }
}
