<app-pageheader [Parent]="this"></app-pageheader>
<div class="message is-light is-small" style="margin-bottom: 1rem;">
  <div class="message-body has-text-grey" style="padding: 0.5rem; font-size: 0.9rem;">
    <span class="icon-text">
      <span class="icon">
        <i class="fas fa-info-circle"></i>
      </span>
      <span>Click on a chart to enter full-page mode for dataset management, or alternatively, hover to view data points</span>
    </span>
  </div>
</div>
<div class="columns is-multiline">
   <div class="column is-6" *ngFor="let chart of charts">
     <div style="position: relative;" (click)="openFullPageStats(chart)" style="cursor: pointer;">
       <app-dashboard-chart 
       [chartType]="chart.chartType"
       [stacked]="chart.stacked"
       [dataSetRequests]="chart.dataSetRequests"
       [title]="chart.title"
       [ShowFilters]="false"
       [isFullPage]="false"
       [selectedDateRange]="chart.selectedDateRange"
       [GroupBy]="chart.groupBy"
       [bucketSize]="chart.bucketSize"
       [asPercentage]="chart.asPercentage"
       [yAxisLabel]="chart.yAxisLabel">
     </app-dashboard-chart>
     </div>
   </div>
</div>