import { Component, ElementRef, Input, OnInit, QueryList,  ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { Options } from '@angular-slider/ngx-slider';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { Bounds, Geo } from 'src/app/util/geo';
import { v4 as uuidv4 } from 'uuid';
import { GoogleMap, MapPolygon } from '@angular/google-maps';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { MediaService } from 'src/app/Services/media.service';
import { BarrierMapItem, CameraMapItem, ControllerMapItem, GateMapItem, GatewayMapItem, SignMapItem, LaneMapItem, MapItemBase, ParkingLevelMapItem, ParkingRowMapItem, ParkingSpaceMapItem, ShapeMapItem, TrafficLightMapItem, GuidanceLightMapItem, CarCounterMapItem } from './mapitems';
import { MapEditorArrowTool, MapEditorPillarTool, MapEditorBarrierTool, MapEditorBoxTool, MapEditorSignTool, MapEditorBusinessTool, MapEditorCameraTool, MapEditorControllerTool, MapEditorGateTool, MapEditorGuardRailTool, MapEditorIslandTool, MapEditorLaneTool, MapEditorParkingSpaceTool, MapEditorPedestrianZoneTool, MapEditorRowTool, MapEditorSolidWallTool, MapEditorToolBase, MapEditorRampTool, MapEditorGrassIslandTool, MapEditorBushTool, MapEditorTallTreeTool, MapEditorSmallTreeTool, MapEditorStairwellTool, MapEditorGardenBedTool, MapEditorGatewayTool, MapEditorTrafficLightTool, MapEditorGuidanceLightTool, MapEditorCarCounterTool, MapEditorCustomSvgTool } from './maptools';
import * as turf from '@turf/turf'
import { Polygon, bearing, destination, distance, point } from '@turf/turf';
import { JsonPipe } from '@angular/common';
import { Geography } from 'src/app/util/geography';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoginService } from 'src/app/auth/login.service';
import { EditcontrolledareaterminalComponent } from 'src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareaterminal/editcontrolledareaterminal.component';
import { EditcontrolledareagateComponent } from 'src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareagate/editcontrolledareagate.component';
import { EditcontrolledarealaneComponent } from 'src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledarealane/editcontrolledarealane.component';
import { EditcontrolledareacameraComponent } from 'src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareacamera/editcontrolledareacamera.component';
import { EditcontrolledareabarrierComponent } from 'src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareabarrier/editcontrolledareabarrier.component';
import { ParkinglevelselectorComponent } from '../../../modals/parkinglevelselector/parkinglevelselector.component';
import { EditGatewayConfigurationComponent } from '../../../modals/editgatewayconfiguration/editgatewayconfiguration.component';
import { EditSignConfigurationComponent } from '../../../modals/editsignconfiguration/editsignconfiguration.component';
import { EditcontrolledareatrafficlightComponent } from 'src/app/features/parking/accesscontrol/controlledareas/edit/editcontrolledareatrafficlight/editcontrolledareatrafficlight.component';

@Component({
  selector: 'app-levelmapeditor',
  templateUrl: './levelmapeditor.component.html',
  styleUrls: ['./levelmapeditor.component.scss']
})
export class LevelmapeditorComponent implements OnInit {

  public Form !: UntypedFormGroup;
  @Input()
  public ModelEditor !: ModelEditor;
  domSanitizer: any;

  @Input()
  public set Level(value: UntypedFormGroup) {  
    this.Form = value;
    this.BuildMapItemsForForm(value);
    this.Form.markAsPristine();
    //this.UpdateAllItemEditorPoints();
  }
  @ViewChildren(MapPolygon)
  public MapPolygons: QueryList<MapPolygon> | null = null;
  //#region Properties
  public MapItems: MapItemBase[] = [];
  public MapItemsByType: Map<string, MapItemBase[]> = new Map<string, MapItemBase[]>();
  public Geo = Geo;
  public SelectedTool: MapEditorToolBase | any = null;
  public SelectedItems: any[] = [];
  public SelectionBounds: Bounds | null = null;
  public ViewScale: number = 10000;
  public DesignSurfacePixelsPerKm: number = 10000;
  public DragStartEvent: any | null = null;
  public DragItem: MapItemBase | null = null;
  public DragDirection: string = "";
  public DragPoint: number[] | null = null;
  public DragType: string = "";
  public DragHasCloned: boolean = false;
  public DragActive : boolean = false;
  public Snapping: boolean = true;
  public SnapDistance: number = 0.0005;
  public CurrentSnapX: number | null = null;
  public CurrentSnapY: number | null = null;
  public PanStartPosition: number[] = [];
  public Marquee: string | null = null;
  public MarqueeBounds: Bounds | null = null;
  public GoogleMapLevelPath: google.maps.LatLngLiteral[] = [];
  public GoogleMapSpaces: google.maps.LatLngLiteral[][] = [];
  public GoogleMapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  public GoogleMapRotation: number = 90;
  public GoogleMapScaleX: number = 1;
  public GoogleMapScaleY: number = 1;

  public ParkingSpaceTypes: any[] = [];
  public BusinessDirectories: any[] = [];

  public InitialSensor: any = {};
  public InitialGateway: any = {};
  public InitialSign: any = {};

  @ViewChild(GoogleMap)
  public GoogleMap !: GoogleMap;
  @ViewChild("DesignSurface")
  public DesignSurface !: ElementRef;

  public Tools: any[] = [
    { Name: "Row", Tool: new MapEditorRowTool(), Icon: "" },
    { Name: "Space", Tool: new MapEditorParkingSpaceTool(), Icon: "" },
    {
      Name: "Access Control", Tools: [
        { Name: "Gate", Tool: new MapEditorGateTool(), Icon: "" },
        { Name: "Lane", Tool: new MapEditorLaneTool(), Icon: "" },
        { Name: "Controller", Tool: new MapEditorControllerTool(), Icon: "" },
        { Name: "Camera", Tool: new MapEditorCameraTool(), Icon: "" },
        { Name: "Barrier", Tool: new MapEditorBarrierTool(), Icon: "" },
        { Name: "Traffic Light", Tool: new MapEditorTrafficLightTool(), Icon: ""}
      ], Icon: ""
    },
    {
      Name: "Hardware", Tools:[
        { Name: "Gateway", Tool: new MapEditorGatewayTool(), Icon: "" },
        { Name: "Sign", Tool: new MapEditorSignTool(), Icon: "" },
        { Name: "GuidanceLight", Tool: new MapEditorGuidanceLightTool(), Icon: "" },
        { Name: "Car Counter", Tool: new MapEditorCarCounterTool(), Icon: ""},
      ]
    },
    {
      Name: "Objects", Tools: [
        { Name: "Business", Tool: new MapEditorBusinessTool(), Icon: "" },
        { Name: "Island", Tool: new MapEditorIslandTool(), Icon: "" },
        { Name: "Grass Island", Tool: new MapEditorGrassIslandTool(), Icon:""},
        { Name: "Garden Bed", Tool: new MapEditorGardenBedTool(), Icon: ""},
        { Name: "Wall", Tool: new MapEditorSolidWallTool(), Icon: "" },
        { Name: "Guard Rail", Tool: new MapEditorGuardRailTool(), Icon: "" },
        { Name: "PedestrianZone", Tool: new MapEditorPedestrianZoneTool(), Icon: "" },
        { Name: "Pillar", Tool: new MapEditorPillarTool(), Icon: "" },
        { Name: "Ramp", Tool: new MapEditorRampTool(), Icon: "" },
        { Name: "Bush", Tool: new MapEditorBushTool(), Icon: "" },
        { Name: "Tall Tree", Tool: new MapEditorTallTreeTool(), Icon: "" },
        { Name: "Small Tree", Tool: new MapEditorSmallTreeTool(), Icon: "" },
        { Name: "Stairwell", Tool: new MapEditorStairwellTool(), Icon: "" },
        { Name: "Custom Svg", Tool: new MapEditorCustomSvgTool(), Icon: ""}
      ], Icon: ""
    },
    {
      Name: "Shapes", Tools: [
        { Name: "Arrow", Tool: new MapEditorArrowTool(), Icon: "" },
        { Name: "Box", Tool: new MapEditorBoxTool(), Icon: "" }
      ], Icon: ""
    }
  ];
  //#endregion


  constructor(private apiService: ApiService, private modalService: SimpleModalService, public toastr: ToastrService, private organizationsService: OrganizationsService, private loginService: LoginService, public sanitizer: DomSanitizer, public mediaService: MediaService, public formBuilder: FormBuilder) { }


  ngOnInit(): void {
    this.UpdateAllItemEditorPoints();
    this.GetBusinessDirectories();
    this.GetParkingSpaceTypes();
    this.Form.markAsPristine();
    if(this.GoogleMapCenter.lat == 0){
      this.organizationsService.GetOrganization(this.loginService.CurrentOrganizationId()).then((x: any) => {
        this.GoogleMapCenter = {lat: x.LocationPoints[1], lng: x.LocationPoints[0]};
      });
    }
  }

  AddAssignment() {
    const portAssignments = this.SelectedItems[0]?.FormControl.get('PortAssignments');
    if (portAssignments) {
        portAssignments.push(this.createPortAssignment()); // Create a new form control for PortAssignment
    }
}

public MediaIdChanged(event: any){
  this.SelectedItems[0].FormControl.get("MediaId").setValue(event);
}

public MediaIdsMisMatch(mediaId: any){
  var x = this.MapItemsByType.get("Shape")?.filter(x => x.FormControl.get("Type")?.value == "CustomSvg" && x.FormControl.get("MediaId")?.value != mediaId);
  if(x != null && x.length > 0){
    return true;
  }
  return false;
}

public LineUpMedia(mediaId: any){
  var x = this.MapItemsByType.get("Shape")?.filter(x => x.FormControl.get("Type")?.value == "CustomSvg");
  if(x != null && x.length > 0){
    x.forEach(mapItem => {
      mapItem.FormControl.get("MediaId")?.setValue(mediaId);
    });
  }
}

private createPortAssignment(): FormGroup {
    return this.formBuilder.group({
        Name: ['Port'], // Initialize with default values if needed
        SpaceId: [null] // Initialize with default values if needed
    });
}

  public GetBusinessDirectoryName(businessId: any) {
    if (businessId == null || businessId == "") {
      return;
    }
    var business = this.BusinessDirectories.filter(x => x.Id == businessId);
    return business[0].Name;
  }

  BusinessIdChange(business: any) {
    var t = business;
    this.SelectedItems[0].BusinessDirectoryId = t.Id;
  }

  public BuildMapItemsForForm(form: UntypedFormGroup) {
    this.MapItems = [];
    this.MapItemsByType = new Map<string, MapItemBase[]>();
    let levelPoly = form.get("PolygonPoints")?.value;
    if (levelPoly == null || levelPoly.length == 0) {
      levelPoly = Geo.NewPolygonAtCenter([0.06, 0.03], 0.1, 0.05);
    }
    this.AddMapItem(new ParkingLevelMapItem(form, levelPoly));
    this.GoogleMapScaleX = this.Form.get("GeoScaleX")?.value * 10000;
    if (this.GoogleMapScaleX == null) this.GoogleMapScaleX = 0.00902 * 10000;
    this.GoogleMapScaleY = this.Form.get("GeoScaleY")?.value * 10000;
    if (this.GoogleMapScaleY == null) this.GoogleMapScaleY = 0.00902 * 10000;
    this.GoogleMapRotation = this.Form.get("GeoRotation")?.value;
    if (this.GoogleMapRotation == null) this.GoogleMapRotation = 90;


    let rows = form.get("Rows") as UntypedFormArray;
    if (rows == null) {
      rows = new UntypedFormArray([]);
      form.setControl("Rows", rows);
    }
    for (let row of rows.controls) {
      let rowPoly = row.get('PolygonPoints')?.value;
      if (rowPoly == null || rowPoly.length == 0) rowPoly = Geo.NewPolygonAtCenter([0.03, 0.03], 0.01, 0.01);
      let rowMapItem = new ParkingRowMapItem(row, rowPoly);
      this.AddMapItem(rowMapItem);

      let gls = row.get("GuidanceLightConfigurations") as UntypedFormArray;
      if (gls == null) {
        gls = new UntypedFormArray([]);
        form.setControl("GuidanceLightConfigurations", gls);
      }
      else {
        for (let guidanceLight of this.ModelEditor.FormArray(row, "GuidanceLightConfigurations").controls) {
          let item = new GuidanceLightMapItem(guidanceLight, rowMapItem, guidanceLight.get("LocationPoints")?.value);
          this.AddMapItem(item);
        }
      }

      let spaces = row.get("Spaces") as UntypedFormArray;
      if (spaces != null) {
        for (let space of spaces.controls) {
          this.AddMapItem(new ParkingSpaceMapItem(space, rowMapItem, space.get("PolygonPoints")?.value));
        }
      }


      const parkingSpaceArray = this.MapItemsByType.get('ParkingSpace');
      parkingSpaceArray?.sort((a, b) => {
          const nameA = a.FormControl.get('Name')?.value.toLowerCase();
          const nameB = b.FormControl.get('Name')?.value.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

    }
    if (rows.controls.length == 0) {
      //there must always be at least 1 row
      let rowPoly = Geo.NewPolygonAtCenter([0.03, 0.03], 0.01, 0.01);
      let f = this.ModelEditor.AddToFormArray(rows, { ClassName: "ParkingRow", Name: "Row", PolygonPoints: rowPoly, EditorLocked : false }, "Rows");
      this.AddMapItem(new ParkingRowMapItem(f, rowPoly));
    }
    if (this.Form.get("Gates") == null) {
      //no gates array on the Level
      this.Form.addControl("Gates", new UntypedFormArray([]));
    }
    else {
      for (let gate of this.ModelEditor.FormArray(this.Form, "Gates").controls) {
        let item = new GateMapItem(gate, gate.get("PolygonPoints")?.value);
        this.AddMapItem(item);
        for (let lane of this.ModelEditor.FormArray(gate, "Lanes")?.controls) {
          let l = new LaneMapItem(lane, lane.get("PolygonPoints")?.value);
          this.AddMapItem(l);
          for (let camera of this.ModelEditor.FormArray(lane, "Cameras")?.controls) {
            let c = new CameraMapItem(camera, camera.get("LocationPoints")?.value);
            this.AddMapItem(c);
          }
          for (let barrier of this.ModelEditor.FormArray(lane, "Barriers")?.controls) {
            let b = new BarrierMapItem(barrier, barrier.get("LocationPoints")?.value);
            this.AddMapItem(b);
          }
          for (let trafficLight of this.ModelEditor.FormArray(lane, "TrafficLights")?.controls) {
            let t = new TrafficLightMapItem(trafficLight, trafficLight.get("LocationPoints")?.value);
            this.AddMapItem(t);
          }
        }
      }
    }
    if (this.Form.get("Controllers") == null) {
      //no gates array on the Level
      this.Form.addControl("Controllers", new UntypedFormArray([]));
    }
    else {
      for (let controller of this.ModelEditor.FormArray(this.Form, "Controllers").controls) {
        let item = new ControllerMapItem(controller, controller.get("LocationPoints")?.value);
        this.AddMapItem(item);
      }
    }
    if (this.Form.get("MapItems") == null) {
      //no gates array on the Level
      this.Form.addControl("MapItems", new UntypedFormArray([]));
    }
    else {
      for (let controller of this.ModelEditor.FormArray(this.Form, "MapItems").controls) {
        let item = new ShapeMapItem(controller, controller.get("PolygonPoints")?.value);
        this.AddMapItem(item);
      }
    }
    if (this.Form.get("GatewayConfigurations") == null) {
      this.Form.addControl("GatewayConfigurations", new UntypedFormArray([]));
    }
    else {
      for (let gateway of this.ModelEditor.FormArray(this.Form, "GatewayConfigurations").controls) {
        let item = new GatewayMapItem(gateway, gateway.get("LocationPoints")?.value);
        this.AddMapItem(item);
      }
    }
    if (this.Form.get("SignConfigurations") == null) {
      this.Form.addControl("SignConfigurations", new UntypedFormArray([]));
    }
    else {
      for (let sign of this.ModelEditor.FormArray(this.Form, "SignConfigurations").controls) {
        let item = new SignMapItem(sign, sign.get("LocationPoints")?.value);
        this.AddMapItem(item);
      }
    }
    if (this.Form.get("CarCounterConfigurations") == null) {
      this.Form.addControl("CarCounterConfigurations", new UntypedFormArray([]));
    }
    else {
      for (let sign of this.ModelEditor.FormArray(this.Form, "CarCounterConfigurations").controls) {
        let item = new CarCounterMapItem(sign, sign.get("LocationPoints")?.value);
        this.AddMapItem(item);
      }
    }

    this.UpdateGoogleMapPath(null, null);

  }
  public AddMapItem(item: MapItemBase) {
    item.Editor = this;
    this.MapItems.push(item);
    if (this.MapItemsByType.has(item.ClassName)) {
      this.MapItemsByType.get(item.ClassName)?.push(item);
    }
    else {
      this.MapItemsByType.set(item.ClassName, [item]);
    }
    item.UpdatePoints();
  }
  public RemoveMapItem(item: MapItemBase) {
    if (item.FormControl != null) {
      let fa = item.FormControl.parent as UntypedFormArray;
      let index = fa.controls.indexOf(item.FormControl);
      fa.removeAt(index);
    }
    let index = this.MapItems.indexOf(item);
    if (index >= 0) this.MapItems.splice(index, 1);
    if (this.MapItemsByType.has(item.ClassName)) {
      let typeArray = this.MapItemsByType.get(item.ClassName);
      if (typeArray != null) {
        let i = typeArray.indexOf(item);
        if (i > -1) {
          typeArray.splice(i, 1);
        }
      }
    }
    index = this.SelectedItems.indexOf(item);
    if (index > -1) {
      this.SelectedItems.splice(index, 1);
    }
  }
  public SelectTool(tool: any) {
    this.SelectedTool = tool.Tool;
  }

  SetParkingSpace(assignment: FormGroup, event: any) {
    assignment?.get('ParkingSpaceId')?.setValue(event.target.value);
  }

  public SetScale(scale: number) {
    this.ViewScale = scale;
    for (let i of this.MapItems) {
      i.UpdatePoints();
    }
  }

  public DesignSurfaceClick(evt: any) {
    if (this.SelectedTool != null) {
      let y = evt.layerY;
      let x = evt.layerX;
      let location = [x * (1 / this.ViewScale), y * (1 / this.ViewScale)];
      this.ClearSelection();
      let newItem = this.SelectedTool.Execute(this, location);
      if (newItem != null) {
        this.CheckMapItemParenting(newItem);
        this.AddItemToSelection(newItem);
      }
      this.SelectedTool = null;
    }
  }

  public ItemMouseDown(evt: any, item: any) {
    evt.stopPropagation();
    this.DragStartEvent = evt;
    if (evt.which == 2) {
      this.DragType = "pan";
      evt.preventDefault();
      this.PanStartPosition = [this.DesignSurface.nativeElement.scrollLeft, this.DesignSurface.nativeElement.scrollTop];
      return;
    }

    if (item.Selected != true) {
      this.DragType = "marquee";
      this.DragStartEvent = evt;
      console.log("Item mouse down, setting DragStartEvent");
      return;
    }

    this.DragType = "itemdrag";
    this.DragItem = item;
    this.DragPoint = null;
    this.DragHasCloned = false;


    if (item.Selected != true) {
      if (evt.shiftKey == false)
        this.ClearSelection();
      this.AddItemToSelection(item);
    }
    for (let i of this.SelectedItems) {
      i.PreDragPolygonPoints = [];
      for (let point of i.PolygonPoints)
        i.PreDragPolygonPoints.push(Array.from(point));
    }
  }
  public PointMouseDown(evt: any, item: any, point: number[], index: number) {
    evt.stopPropagation();
    this.DragStartEvent = evt;
    if (evt.ctrlKey && evt.altKey) {
      //ctrl+alt click to remove points
      item.PolygonPoints.splice(index, 1);
      item.UpdatePoints();
      //abort any durther dragging
      this.DragItem = null; this.DragStartEvent = null; this.DragPoint = null;
      return;
    }
    else if (evt.ctrlKey) {
      this.DragType = "pointdrag";
      item.PreDragPolygonPoints = [Array.from(point)]; //dragging a point only needs the 1 point
    }
    else {
      this.DragType = "pointresize";
      this.DragDirection = Geo.DirectionFromCenter(point, item.PolygonPoints);
      item.PreDragPolygonPoints = []; //scaling the item needs to know all points on the poly
      for (let i of item.PolygonPoints)
        item.PreDragPolygonPoints.push(Array.from(i));
    }
    this.DragItem = item;
    this.DragPoint = point;
    if (item.Selected != true) {
      this.ClearSelection();
      this.AddItemToSelection(item);
    }
  }
  public RotateMouseDown(evt: any) {
    evt.stopPropagation();
    this.DragType = "rotate";
    this.DragItem = this.SelectedItems[0];
    this.DragPoint = null;
    this.DragStartEvent = evt;
    for (let item of this.SelectedItems) {
      item.PreDragPolygonPoints = []; //scaling the item needs to know all points on the poly
      for (let i of item.PolygonPoints)
        item.PreDragPolygonPoints.push(Array.from(i));
    }
  }
  public BisectionPointMouseDown(evt: any, point: number[], item: any, index: number) {
    if (evt.ctrlKey) { //only bisect and start drag if ctrl key pressed
      let p1 = item.PolygonPoints[index];
      let p2 = index == item.PolygonPoints.length - 1 ? item.PolygonPoints[0] : item.PolygonPoints[index + 1];
      let b = Geo.BisectPoints(p1, p2);
      item.PolygonPoints.splice(index + 1, 0, b);
      this.PointMouseDown(evt, item, b, index);
    }
    else {
      this.PointMouseDown(evt, item, point, index);
    }

  }
  public DesignSurfaceMouseDown(evt: any) {
    evt.stopPropagation();

    this.DragStartEvent = evt;
    if (evt.which == 2) {
      this.DragType = "pan";
      evt.preventDefault();
      this.PanStartPosition = [this.DesignSurface.nativeElement.scrollLeft, this.DesignSurface.nativeElement.scrollTop];
    } else {
      this.DragType = "marquee";
    }
    this.DragActive = false;
    this.DragItem = null;
    this.DragPoint = null;
    if(!evt.shiftKey){
      this.ClearSelection();
    }
  }
  public DesignSurfaceMouseMove(evt: any) {
    evt.stopPropagation();
    if (this.DragStartEvent != null && (evt.buttons == 1 || evt.buttons == 4) && evt.button == 0) {
      let moveXpixels = evt.pageX - this.DragStartEvent.pageX;
      let moveYpixels = evt.pageY - this.DragStartEvent.pageY;
      let moveX = moveXpixels / this.ViewScale;
      let moveY = moveYpixels / this.ViewScale;
      if (this.DragActive == false && Math.abs(moveX) < 0.0003 && Math.abs(moveY) < 0.0003) {
        //dont start dragging until at least 5px to avoid minor moves on click
        return;
      }
      this.DragActive = true;
      switch (this.DragType) {
        case "itemdrag":
          if (evt.altKey && this.DragHasCloned == false) {
            //user is alt dragging to make a clone
            console.log("alt key held during drag, cloning selected items");
            let clones: any[] = [];
            for (let i of this.SelectedItems) {
              let clone = i.Clone();
              clones.push(clone);
              i.Selected = false;
              clone.Selected = true;
            }
            this.SelectedItems = clones;
            this.DragHasCloned = true;
            this.DragItem = this.SelectedItems[0];
          }
          console.log("drag point[0] before: " + this.DragItem?.PolygonPoints[0][0] + ", " + this.DragItem?.PolygonPoints[0][1]);

          let snap = null;

          for (let i of this.SelectedItems) {
            if (i.FormControl.get('EditorLocked')?.value == true) continue;
            for (let p = 0; p < i.PolygonPoints.length; p++) {
              i.PolygonPoints[p][0] = Math.round((i.PreDragPolygonPoints[p][0] + moveX) * (this.ViewScale)) / (this.ViewScale);
              i.PolygonPoints[p][1] = Math.round((i.PreDragPolygonPoints[p][1] + moveY) * (this.ViewScale)) / (this.ViewScale);
            }
            if (this.Snapping == true && snap == null)
              snap = this.FindSnap();
            console.log("drag move to " + evt.pageX + ", " + evt.pageY + ", total " + moveX + "," + moveY + " put point[0] at " + i.PolygonPoints[0][0] + ", " + i.PolygonPoints[0][1]);
            if (snap != null) {
              for (let p of i.PolygonPoints) {
                p[0] -= snap[0];
                p[1] -= snap[1];
              }
              console.log("snapping put point[0] at " + i.PolygonPoints[0][0] + ", " + i.PolygonPoints[0][1]);
            }
            i.UpdatePoints();
          }
          break;
        case "pointdrag":
          if (this.DragPoint != null && this.DragItem != null && this.DragItem.FormControl.get('EditorLocked')?.value != true) {
            this.DragPoint[0] = this.DragItem.PreDragPolygonPoints[0][0] + moveX;
            this.DragPoint[1] = this.DragItem.PreDragPolygonPoints[0][1] + moveY;
            if (this.Snapping == true) {
              let snap = this.FindSnap();
              console.log("pointdrag move to " + evt.pageX + ", " + evt.pageY + ", total " + moveX + "," + moveY + " put point[0] at " + this.DragPoint[0] + ", " + this.DragPoint[1]);
              if (snap != null) {
                this.DragPoint[0] -= snap[0];
                this.DragPoint[1] -= snap[1];
                console.log("snapping put point[0] at " + this.DragPoint[0] + ", " + this.DragPoint[1]);
              }
            }
            this.DragItem.UpdatePoints();
            this.UpdateGoogleMapPath(null, null);
          }
          break;
        case "pointresize":
          if (this.DragPoint != null && this.DragItem != null && this.DragItem.FormControl.get('EditorLocked')?.value != true) {

            if (this.DragDirection != "n" && this.DragDirection != "s") {
              //any drag direction except n or s will scale on the X axis
              let leftMostPoint = Geo.LeftMost(this.DragItem.PreDragPolygonPoints);
              let rightMostPoint = Geo.RightMost(this.DragItem.PreDragPolygonPoints);
              let width = rightMostPoint - leftMostPoint;
              let scaleX = moveX / width;
              let offsetX = 0;
              if (this.DragDirection == "nw" || this.DragDirection == "sw" || this.DragDirection == "w") {
                //dragging the left side so need to move left points
                for (let i = 0; i < this.DragItem.PolygonPoints.length; i++) {
                  this.DragItem.PolygonPoints[i][0] = this.DragItem.PreDragPolygonPoints[i][0] + (rightMostPoint - this.DragItem.PreDragPolygonPoints[i][0]) * scaleX;
                }
              }
              else {
                //dragging the right side so move the right points
                for (let i = 0; i < this.DragItem.PolygonPoints.length; i++) {
                  this.DragItem.PolygonPoints[i][0] = this.DragItem.PreDragPolygonPoints[i][0] + (this.DragItem.PreDragPolygonPoints[i][0] - leftMostPoint) * scaleX;
                }
              }
            }

            if (this.DragDirection != "w" && this.DragDirection != "e") {
              //any drag direction except w or e will scale on the Y axis
              let topMostPoint = Geo.TopMost(this.DragItem.PreDragPolygonPoints);
              let bottomMostPoint = Geo.BottomMost(this.DragItem.PreDragPolygonPoints);
              let height = bottomMostPoint - topMostPoint;
              let scaleY = moveY / height;
              if (this.DragDirection == "sw" || this.DragDirection == "se" || this.DragDirection == "s") {
                for (let i = 0; i < this.DragItem.PolygonPoints.length; i++) {
                  this.DragItem.PolygonPoints[i][1] = this.DragItem.PreDragPolygonPoints[i][1] + (this.DragItem.PreDragPolygonPoints[i][1] - topMostPoint) * scaleY;
                }
              }
              else {
                for (let i = 0; i < this.DragItem.PolygonPoints.length; i++) {
                  this.DragItem.PolygonPoints[i][1] = this.DragItem.PreDragPolygonPoints[i][1] + (bottomMostPoint - this.DragItem.PreDragPolygonPoints[i][1]) * scaleY;
                }
              }
            }
            this.DragItem.UpdatePoints();
          }
          break;
        case "rotate":
          if (this.SelectionBounds != null) {
            let originX = (this.SelectionBounds?.Right - this.SelectionBounds?.Left) / 2 + this.SelectionBounds?.Left;
            let originY = (this.SelectionBounds?.Bottom - this.SelectionBounds?.Top) / 2 + this.SelectionBounds?.Top;
            let angle = Geo.AngleFromPoint([this.DragStartEvent.layerX, this.DragStartEvent.layerY], [evt.layerX, evt.layerY]);
            if (evt.shiftKey) {
              angle = Math.ceil(angle / 15) * 15;
            }
            console.log("rotate angle is " + angle + " degrees");
            for (let item of this.SelectedItems) {
              if (item.FormControl.get('EditorLocked')?.value == true) continue;
              item.PolygonPoints = Geo.RotatePointsAroundOrigin(item.PreDragPolygonPoints, [originX, originY], angle);
              item.DisplayAngle = Math.round(360 - angle); //css transform is opposite direction to the angle used in this editor to translate points
              item.UpdatePoints();
              item.FormControl.get("DisplayAngle")?.setValue(item.DisplayAngle);
            }
          }
          break;
        case "marquee":
          let x = evt.layerX;
          let y = evt.layerY;
          let x1 = this.DragStartEvent.layerX;// - this.DesignSurface.nativeElement.scrollLeft;
          let y1 = this.DragStartEvent.layerY;// + this.DesignSurface.nativeElement.scrollTop;
          if (evt.target.classList.contains("design-surface")) {
            //when the mouse goes over the design surface element the scroll position is not reported in the layerX/Y position
            y += this.DesignSurface.nativeElement.scrollTop;
            x += this.DesignSurface.nativeElement.scrollLeft;
          }

          var points = [[x1, y1], [x, y1], [x, y], [x1, y]];

          this.MarqueeBounds = Geo.GetPolygonPointBounds(points);
          this.Marquee = this.MarqueeBounds.SvgPath(1);
          break;
        case "pan":
          this.DesignSurface.nativeElement.scrollLeft = Math.round(this.PanStartPosition[0] - moveXpixels);
          this.DesignSurface.nativeElement.scrollTop = Math.round(this.PanStartPosition[1] - moveYpixels);
          console.log("pan move is " + moveXpixels + "," + moveYpixels + " resulting in scroll location " + this.DesignSurface.nativeElement.scrollLeft + ", " + this.DesignSurface.nativeElement.scrollTop);
          break;
      }
    }
  }
  public DesignSurfaceKeyPress(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    switch (evt.key) {
      case "ArrowUp":
        for (let item of this.SelectedItems) {
          for (let point of item.PolygonPoints) {
            point[1] -= 0.0001;
          }
          item.UpdatePoints();
        }
        break;
      case "ArrowDown":
        for (let item of this.SelectedItems) {
          for (let point of item.PolygonPoints) {
            point[1] += 0.0001;
          }
          item.UpdatePoints();
        }
        break;
      case "ArrowLeft":
        for (let item of this.SelectedItems) {
          for (let point of item.PolygonPoints) {
            point[0] -= 0.0001;
          }
          item.UpdatePoints();
        }
        break;
      case "ArrowRight":
        for (let item of this.SelectedItems) {
          for (let point of item.PolygonPoints) {
            point[0] += 0.0001;
          }
          item.UpdatePoints();
        }
        break;
      case "Delete":
        for (let item of this.SelectedItems) {

          this.RemoveMapItem(item);
        }
        break
    }
  }
  public DesignerMouseWheel(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    let beforeX = evt.layerX / this.ViewScale;
    let beforeY = evt.layerY / this.ViewScale;
    this.ViewScale += evt.wheelDeltaY * 5;
    this.UpdateAllItemEditorPoints();
    let afterX = evt.layerX / this.ViewScale;
    let afterY = evt.layerY / this.ViewScale;
    let xPixels = (afterX - beforeX) * this.ViewScale;
    let yPixels = (afterY - beforeY) * this.ViewScale;
    this.DesignSurface.nativeElement.scrollTop -= yPixels;
    this.DesignSurface.nativeElement.scrollLeft -= xPixels;
  }
  public FindSnap(): number[] | null {
    let offsetX: number | null = null;
    let offsetY: number | null = null;
    for (let i of this.MapItems) {
      if (i.PolygonPoints == null || i.PolygonPoints === undefined) continue;
      for (let point of i.PolygonPoints) {
        if (this.DragPoint != null) {
          if (point == this.DragPoint) continue; //don't snap point to itself
          let xdiff = this.DragPoint[0] - point[0];
          if (Math.abs(xdiff) < this.SnapDistance) {
            //point is within snapping distance
            if (offsetX == null || Math.abs(xdiff) < Math.abs(offsetX)) {
              //this point is closer than previous potential snapping point
              offsetX = Math.round(xdiff * this.DesignSurfacePixelsPerKm) / this.DesignSurfacePixelsPerKm;
            }
          }
          let ydiff = this.DragPoint[1] - point[1];
          if (Math.abs(ydiff) < this.SnapDistance) {
            //point is within snapping distance
            if (offsetY == null || Math.abs(ydiff) < Math.abs(offsetY)) {
              //this point is closer than previous potential snapping point
              offsetY = Math.round(ydiff * this.DesignSurfacePixelsPerKm) / this.DesignSurfacePixelsPerKm;
            }
          }
        }
        else if (this.DragItem != null) {
          if (i == this.DragItem || i.Selected == true) continue; //when dragging items don't snap them to themselves
          for (let ipoint of this.DragItem.PolygonPoints) {
            let xdiff = ipoint[0] - point[0];
            if (Math.abs(xdiff) < this.SnapDistance) {
              //point is within snapping distance
              if (offsetX == null || Math.abs(xdiff) < Math.abs(offsetX)) {
                //this point is closer than previous potential snapping point
                offsetX = Math.round(xdiff * this.DesignSurfacePixelsPerKm) / this.DesignSurfacePixelsPerKm;
              }
            }
            let ydiff = ipoint[1] - point[1];
            if (Math.abs(ydiff) < this.SnapDistance) {
              //point is within snapping distance
              if (offsetY == null || Math.abs(ydiff) < Math.abs(offsetY)) {
                //this point is closer than previous potential snapping point
                offsetY = Math.round(ydiff * this.DesignSurfacePixelsPerKm) / this.DesignSurfacePixelsPerKm;
              }
            }
          }
        }
      }
    }
    if (offsetX != null || offsetY != null) {
      //yes we are in snapping range
      console.log("Snapping resulted in offset of X=" + offsetX + ", Y=" + offsetY);
      return [offsetX ?? 0, offsetY ?? 0];
    }
    console.log("Snapping resulted in NO offset");
    return null;
  }
  public DesignSurfaceMouseUp(evt: any) {
   evt.stopPropagation();
   if(this.DragActive)
   {
    evt.stopPropagation();
    switch (this.DragType) {
      case "itemdrag":
        //if the item moved was a parking space recheck the row that it sits within
        for (let i of this.SelectedItems) {
          for(let j=1; j < i.PreDragPolygonPoints.length; j++){
            i.PolygonPoints[j][0] = i.PolygonPoints[0][0]+(i.PreDragPolygonPoints[j][0]-i.PreDragPolygonPoints[0][0]);
            i.PolygonPoints[j][1] = i.PolygonPoints[0][1]+(i.PreDragPolygonPoints[j][1]-i.PreDragPolygonPoints[0][1]);
          }

          this.CheckMapItemParenting(i);
        }
        this.DragStartEvent = null;
        this.DragActive = false;
        break;
      case "marquee":
        if (this.DragActive && this.MarqueeBounds != null) {
          this.MarqueeBounds.Scale(1 / this.ViewScale);
          this.DragStartEvent = null;
          if(!evt.shiftKey)
            this.ClearSelection();
          for (let i of this.MapItems) {
            if (i.PolygonPoints == null || i.PolygonPoints === undefined) continue;
            for (let point of i.PolygonPoints) {
              if (this.MarqueeBounds.Contains(point)) {
                this.AddItemToSelection(i);
                this.DragActive = false;
                continue;
              }
            }
          }
        }
        this.DragStartEvent = null;
        break;
      //default:
      //  this.ClearSelection();
    }
  }
    //this.DragStartEvent = null;
    this.DragType = "";
    this.DragActive = false;
    this.Marquee = null;
  }

  public ItemClicked(evt: any, item: any) {
    if (this.DragStartEvent == null|| this.DragActive == true) {
      //event was already handled in between so abort the click
      return;
    }
    if (evt.shiftKey == false) {
      this.ClearSelection();
      this.AddItemToSelection(item);
    }
    else { //shift key
      if (item.Selected != true)
        this.AddItemToSelection(item);
      else
        this.RemoveItemFromSelection(item);
    }
    this.DragStartEvent = null;
    this.DragActive = false;
  }

  public ClearSelection() {
    for (let i of this.SelectedItems) {
      i.Selected = false;
    }
    this.SelectedItems = [];
    this.SelectionBounds = null;
  }
  public AddItemToSelection(item: any) {
    if (!this.SelectedItems.includes(item))
      this.SelectedItems.push(item);
    item.Selected = true;
    this.SelectionBounds = Geo.GetPolygonPointBounds(this.SelectedItems);
  }
  public RemoveItemFromSelection(item: any) {
    for (let i = 0; i < this.SelectedItems.length; i++) {
      if (this.SelectedItems[i] == item) {
        this.SelectedItems.splice(i, 1);
      }
    }
    item.Selected = false;
    this.SelectionBounds = Geo.GetPolygonPointBounds(this.SelectedItems);
  }
  public UpdateAllItemEditorPoints() {
    for (let i of this.MapItems) {
      i.UpdatePoints();
    }
  }

  public CheckMapItemParenting(item: MapItemBase) { 
    if (item.RequireParent != null) {
      let potentialParents: MapItemBase[] | undefined = this.MapItemsByType.get(item.RequireParent);
      if (potentialParents) {
        let foundParent: boolean = false;
        for (let p of potentialParents) {
          let bounds = Geo.GetPolygonPointBounds(p);
          if (bounds != null && bounds.ContainsPolygon(item.PolygonPoints)) {
            if (item.ParentProperty != null) {
              item.FormControl.get(item.ParentProperty)?.setValue(p.FormControl.get("Id")?.value);
              console.log(p.FormControl.get("Id")?.value + " is what its been set to");
            }
            if (item.ChildProperty != null) {
              p.FormControl.get(item.ChildProperty)?.setValue(item.FormControl.get("Id")?.value);
            }
            if (item.ParentArray != null) {
              for (let pp of potentialParents) {
                let childArray = pp.FormControl.get(item.ParentArray) as UntypedFormArray;
                let founditem: boolean = false;
                for (let index = 0; index < childArray.controls.length; index++) {
                  let child = childArray.controls[index];
                  if (child == item.FormControl) {
                    if (pp == p) {
                      console.log("Moved item is already a child of the parent");
                      founditem = true;
                    }
                    else {
                      console.log("Moved item was in other parent, removing at index " + index);
                      childArray.removeAt(index);
                      break;
                    }
                  }
                }
                if (pp == p && founditem == false) {
                  console.log("Moved needs to be added to parent");
                   // If lane has changed, give the device a new Id so the database knows to mark the old device as deleted, 
                   // and to create a new device.
                    if(item.ParentProperty == "LaneId"){
                      console.log(item.FormControl.get("Id")?.value + " is the id of the device");
                      let formcontrol = item.FormControl.get("Id") as UntypedFormControl;
                      formcontrol.setValue(uuidv4());
                      console.log(item.FormControl.get("Id")?.value + " is the new id of the device");
                    }
                  childArray.push(item.FormControl);
                }
              }
            }
            foundParent = true;
            break;
          }
        }
        if (foundParent == false) {
          this.toastr.warning("Must be in a " + item.RequireParent, "Placement Error");
          item.PolygonPoints = item.PreDragPolygonPoints;
          item.UpdatePoints();
        }
      }
    }
  }
  public GetParkingSpaceTypes() {
    this.apiService.Get<any>("infrastructure/spacetypes").then((result) => {
      this.ParkingSpaceTypes = result;

      this.ParkingSpaceTypes.forEach(async (o) => {
        await this.mediaService.GetBase64Media(o.MediaId).subscribe(result => {
          var string = result.Base64Prefix + result.Base64String;
          o["HtmlImage"] = string;
        });
      });
      return;
    });
  }

  public GetBusinessDirectories() {
    this.apiService.Get<any>("parking/businesses").then((result) => {
      this.BusinessDirectories = result;
      return;
    });
  }

  public GetHtmlImage(item: any) {
    return this.domSanitizer.bypassSecurityTrustHtml(item.HtmlImage);
  }

  public SetItemHeight(evt: any) {
    let newHeight = parseFloat(evt.target.value) / 1000;
    for (let i of this.SelectedItems) {
      let topMostPoint = Geo.TopMost(i.PolygonPoints);
      let bottomMostPoint = Geo.BottomMost(i.PolygonPoints);
      let height = bottomMostPoint - topMostPoint;
      let scaleY = (newHeight) / height;
      for (let point of i.PolygonPoints) {
        point[1] = topMostPoint + (point[1] - topMostPoint) * scaleY;
      }
      if (i instanceof ParkingLevelMapItem)
        this.UpdateGoogleMapPath(null, null);
      i.UpdatePoints();
    }
  }

  public SetItemWidth(evt: any) {
    let newWidth = parseFloat(evt.target.value) / 1000;
    for (let i of this.SelectedItems) {
      let leftMostPoint = Geo.LeftMost(i.PolygonPoints);
      let rightMostPoint = Geo.RightMost(i.PolygonPoints);
      let width = rightMostPoint - leftMostPoint;
      let scaleX = (newWidth) / width;
      for (let point of i.PolygonPoints) {
        point[0] = leftMostPoint + (point[0] - leftMostPoint) * scaleX;
      }
      if (i instanceof ParkingLevelMapItem)
        this.UpdateGoogleMapPath(null, null);
      i.UpdatePoints();
    }
  }

  public EditLocalController(item: MapItemBase) {
    this.modalService.addModal(EditcontrolledareaterminalComponent, { Form: item.FormControl })
      .subscribe((result) => {
      });
  }
  public EditGate(item: MapItemBase) {
    this.modalService.addModal(EditcontrolledareagateComponent, { Form: item.FormControl })
      .subscribe((result) => {
      });
  }
  public EditLane(item: MapItemBase) {
    this.modalService.addModal(EditcontrolledarealaneComponent, { Form: item.FormControl, Level: this.Form })
      .subscribe((result) => {
      });
  }
  public EditCamera(item: CameraMapItem) {
    this.modalService.addModal(EditcontrolledareacameraComponent, { Form: item.FormControl })
      .subscribe((result) => {
      });
  }
  public EditBarrier(item: BarrierMapItem) {
    this.modalService.addModal(EditcontrolledareabarrierComponent, { Form: item.FormControl })
      .subscribe((result) => {
      });
  }
  public EditTrafficLight(item: TrafficLightMapItem) {
    this.modalService.addModal(EditcontrolledareatrafficlightComponent, { Form: item.FormControl })
      .subscribe((result) => {});
  }
  public EditGatewayConfiguration(item: GatewayMapItem){
    this.modalService.addModal(EditGatewayConfigurationComponent, { Form: item.FormControl })
      .subscribe((result) => {
      });
  }

  public EditCarCounterConfiguration(item: GatewayMapItem){
    this.modalService.addModal(EditGatewayConfigurationComponent, { Form: item.FormControl })
      .subscribe((result) => {
      });
  }

  public EditSignConfiguration(item: SignMapItem){
    this.modalService.addModal(EditSignConfigurationComponent, {Form: item.FormControl })
      .subscribe((result) => {
      });
  }

  public EditGuidanceLightConfiguration(item: GuidanceLightMapItem){
    // this.modalService.addModal(EditSignConfigurationComponent, {Form: item.FormControl })
    //   .subscribe((result) => {
    //   });
  }

  public ImportLevel() {    
    this.modalService.addModal(ParkinglevelselectorComponent, {})
      .subscribe((result) => {
        if (result == null) return;
        this.ModelEditor.Busy();
        this.apiService.Get<any>("infrastructure/parkinglots/" + this.ModelEditor.Model.ParkingLotId + "/levels/" + result).then((toCopy) => {
          //need top copy all the contents and strip out the IDs
          toCopy.Id = this.ModelEditor.ModelId;
          let laneControllerMap = new Map<any, any>();
          for (let g of toCopy.Gates) {
            for (let l of g.Lanes) {
              if (l.LocalControllerId != null) {
                for (let c of toCopy.Controllers) {
                  if (c.Id == l.LocalControllerId) {
                    laneControllerMap.set(l, c);
                    break;
                  }
                }
              }
            }
          }
          this.StripIds(toCopy);
          for (let c of toCopy.Controllers) { //set new IDs for all the controllers
            c.Id = uuidv4();
          }
          for (let i of laneControllerMap.entries()) {
            i[0].LocalControllerId = i[1].Id;
          }
          let fg = new UntypedFormGroup({});
          this.ModelEditor.PopulateFormGroupFromModel(toCopy, fg, "");
          this.ModelEditor.Form = fg;
          this.Form = fg;

          this.BuildMapItemsForForm(this.Form);
          this.ModelEditor.StopBusy();
        });
      });
  }
  private StripIds(item: any) {
    if (item == null) return;
    let keys = Object.keys(item);
    for (let key of keys) {
      if (key == "Id") {
        if (item[key].length > 30)
          item[key] = uuidv4();
        else
          item[key] = null;
      }
      if (Array.isArray(item[key])) {
        for (let a of item[key]) {
          this.StripIds(a);
        }
      }
      else if (typeof (item[key]) == 'object') {
        this.StripIds(item[key]);
      }
    }
  }


  public CalculateEntryPath(evt: any){
    this.SelectedItems[0].entryPath = this.SelectedItems[0].GetSvgPathToNextIndex(evt.target.value);
  }

  public CalculateExitPath(evt:any){
    this.SelectedItems[0].exitPath = this.SelectedItems[0].GetSvgPathToNextIndex(evt.target.value);
  }

  public GetBusiness(businessId: any) {
    if (businessId == null || businessId == "") {
      return;
    }
    var business = this.BusinessDirectories.filter(x => x.Id == businessId);
    return business[0];
  }

  public UpdateGoogleMapPath(rotateDegrees: number | null, scaleFactor: number | null) {
    let geoCenter = this.Form.get("GeoLocationPoints")?.value;
    let levelPoints: number[][] = this.Form.get('PolygonPoints')?.value;
    let levelBounds = Geo.GetPolygonPointBounds(levelPoints);
    console.log("updating level map " + levelPoints + " to coordinates centered on " + geoCenter);
    this.Form.get("GeoRotation")?.setValue(this.GoogleMapRotation);
    let geoCoords: Polygon = Geography.ConvertLocalCoordinatesToTurfPolygon(levelPoints, 1000, geoCenter, levelBounds.Center, this.GoogleMapRotation);

    this.GoogleMapLevelPath = Geography.PolygonToGoogleMaps(geoCoords);
    this.Form.get("GeoPolygonPoints")?.setValue(geoCoords.coordinates[0]);

    this.GoogleMapSpaces = [];
    let spaces = this.MapItemsByType?.get('ParkingSpace');
    if (spaces != null) {
      for (let s of spaces) {
        let geoCoords: Polygon = Geography.ConvertLocalCoordinatesToTurfPolygon(s.PolygonPoints, 1000, geoCenter, levelBounds.Center, this.GoogleMapRotation);
        this.GoogleMapSpaces.push(Geography.PolygonToGoogleMaps(geoCoords));
      }
    }


    console.log("resulting geo coordinates are " + JSON.stringify(this.GoogleMapLevelPath));
    if (this.GoogleMapCenter.lat == 0)
      this.GoogleMapCenter = { lat: geoCenter[1], lng: geoCenter[0] };
    return;
    /*
        this.Form.get("GeoScaleX")?.setValue(this.GoogleMapScaleX / 10000);
        this.Form.get("GeoScaleY")?.setValue(this.GoogleMapScaleY / 10000);
        this.Form.get("GeoRotation")?.setValue(this.GoogleMapRotation);
        console.log("Getting Map Path for level");
        let levelCenter: number[] | null = this.Form.get("GeoLocationPoints")?.value;
        if (levelCenter == null || levelCenter.length != 2)
          levelCenter = this.organizationsService.GetOrganizationLocation();
        if (levelCenter == null) return;
        let levelPoints = this.Form.get('PolygonPoints')?.value;
        let levelBounds = Geo.GetPolygonPointBounds(levelPoints);
        let width = levelBounds.Right - levelBounds.Left;
        let height = levelBounds.Bottom - levelBounds.Top;
        let outlinePoints: number[][] = [];
        for (let point of levelPoints) {
          outlinePoints.push([point[0], point[1]]);
        }
        let localCenter = Geo.PolygonCenterAsNumbers(outlinePoints);
        outlinePoints = Geo.RotatePointsAroundOrigin(outlinePoints, localCenter, this.GoogleMapRotation);
        let invert = levelCenter[0] < 0;
        if (invert) levelCenter[0] = Math.abs(levelCenter[0]);
        for (let point of outlinePoints) {
          point[0] = point[0] * (this.GoogleMapScaleX / 10000);
          point[1] = point[1] * (this.GoogleMapScaleY / 10000);
        }
        let scaledBounds = Geo.GetPolygonPointBounds(outlinePoints);
        for (let point of outlinePoints) {
          point[0] = point[0] + levelCenter[0] - (scaledBounds.Right - scaledBounds.Left) / 2 - scaledBounds.Left;
          point[1] = point[1] + levelCenter[1] - (scaledBounds.Bottom - scaledBounds.Top) / 2 - scaledBounds.Top;
          if (invert) point[0] = 0 - point[0];
        }
        if (invert) {
          levelCenter[0] = -1 * levelCenter[0];
          outlinePoints = outlinePoints.reverse(); //inverting the poly needs to reverse order the points to still be counter clockwise
        }
        let result = Geo.DoublesToLatLng(outlinePoints);
        this.GoogleMapPaths = result;
        if (this.GoogleMapCenter.lat == 0)
          this.GoogleMapCenter = Geo.PolygonCenter(outlinePoints);
          */
  }
  public GoogleMapLevelDragged(evt: any) {
    console.log("Google Map Level Dragged event");
    if (this.MapPolygons != null) {
      let p = this.MapPolygons.last.getPaths().getArray()[0].getArray();
      //find how far the polygon was dragged
      let d = turf.distance(turf.point([this.GoogleMapLevelPath[0].lng, this.GoogleMapLevelPath[0].lat]), turf.point([p[0].lng(), p[0].lat()]));
      let a = turf.bearing(turf.point([this.GoogleMapLevelPath[0].lng, this.GoogleMapLevelPath[0].lat]), turf.point([p[0].lng(), p[0].lat()]));
      let formControl = this.Form.get("GeoLocationPoints");
      let formValue: number[] = formControl?.value as number[];
      let currentGeoLocation = turf.point(formValue);
      let newGeoLocation = turf.destination(currentGeoLocation, d, a);
      formControl?.setValue([newGeoLocation.geometry.coordinates[0], newGeoLocation.geometry.coordinates[1]]);
      this.UpdateGoogleMapPath(null, null);
    }
  }

  public CenterLevelOnMap() {
    let x: any = this.GoogleMap.getCenter();
    this.Form.get("GeoLocationPoints")?.setValue([x.lng(), x.lat()]);
    this.UpdateGoogleMapPath(null, null);
  }

  public UpdateSelectedItemsProperty(propertyName: string) {
    for (let i of this.SelectedItems) {
      if (i.FormControl != null) {
        i.FormControl.get(propertyName).value = this.SelectedItems[0].FormControl.get(propertyName).value;
      }
    }
  }
  
  public OpenBarrier(id : string){
    this.apiService.Post("/infrastructure/barriers/" + id + "/open", {});
  }
  public KeepBarrierOpen(id : string){
    this.apiService.Post("/infrastructure/barriers/" + id + "/keepopen", {});
  }
  public CloseBarrier(id : string){
    this.apiService.Post("/infrastructure/barriers/" + id + "/close", {});
  }
}


