import { ChangeDetectorRef, EventEmitter, Injectable, Output } from "@angular/core";
import { DashboardChartComponent } from "../dashboard-chart.component";
import { ApiService } from "src/app/Services/api.service";
import { ToastrService } from "ngx-toastr";
import { ChartEditModalComponent } from "../../../modals/chart-edit-modal/chart-edit-modal.component";
import { SimpleModalService } from "ngx-simple-modal";
import { ReportingService } from "src/app/Services/reporting.service.spec";
import { AggregateType, ChartType, DataSetRequest, DataSetRequestFilter, DatasetStatistics, GroupByOption } from "../ChartConfiguration";


@Injectable({
    providedIn: 'root'
})
export class ChartDataHandler {

    private dashboardChart!: DashboardChartComponent;
    @Output() chartUpdated = new EventEmitter<{requests: DataSetRequest[], updateYAxis?: boolean}>();
    datasetStatistics : DatasetStatistics[] = [];

    constructor(dashboardChart: DashboardChartComponent, private reportingService: ReportingService, private toastr: ToastrService, private cdr: ChangeDetectorRef, private modalService: SimpleModalService) {
        this.dashboardChart = dashboardChart;

        this.chartUpdated.subscribe((data: {requests: DataSetRequest[], updateYAxis?: boolean}) => {
          this.dashboardChart.dataSetRequests = data.requests;
          const newChartType = data.requests.some(ds => ds.ChartType === 'pie') ? 'pie' : 'bar';
          
          // Handle chart type changes
          if (this.dashboardChart.chartType !== newChartType) {
            this.dashboardChart.chartType = newChartType;
            
            // Reset chart data
            this.dashboardChart.chartDataResponse = {
              datasets: [],
              labels: [],
              stacked: false
            };
            this.dashboardChart.chart.Loading();
          }

          this.dashboardChart.chartStateHandler.saveState();
          this.FetchChartData(data.updateYAxis || false);
        });
    }

    private generateChartTitle(): string {
      const datasetGroups = this.dashboardChart.dataSetRequests.reduce((acc, ds) => {
          const key = `${ds.AggregateType}`;  // Group only by aggregate type
          if (!acc[key]) {
              acc[key] = [];
          }
          acc[key].push(ds);
          return acc;
      }, {} as Record<string, DataSetRequest[]>);

      const titleParts = Object.entries(datasetGroups).map(([aggregateType, datasets]) => {
          const metrics = datasets.map(ds => 
              this.reportingService.getReportableProperty(ds.TargetType, ds.Metric)?.Label
          );
          
          // Join multiple metrics with 'and'
          const metricsText = metrics.length > 1 
              ? metrics.slice(0, -1).join(', ') + ' and ' + metrics[metrics.length - 1]
              : metrics[0];
          
          // Get split by from first dataset (assuming all datasets in group share same split by)
          const splitByPart = datasets[0].SplitBy 
              ? ` by ${this.reportingService.GetSplitByLabel(datasets[0].TargetType, datasets[0].SplitBy)}` 
              : '';
              
          return `${aggregateType} of ${metricsText}${splitByPart}`;
      });

      return titleParts.join(' and ');
  }

    async FetchChartData(updateYAxisLabel : boolean = false) {
      this.reportingService.fetchReportData(this.dashboardChart);
      if(this.dashboardChart.title === '') {
        this.dashboardChart.title = this.generateChartTitle();
      }
      this.dashboardChart.userInterfaceHandler.UpdateOrCheckIsLive();
      if (updateYAxisLabel) {
        this.dashboardChart.userInterfaceHandler.getYAxisLabel();
      }
    }

    // New method to calculate statistics
    public updateDatasetStatistics(): void {
      // First, group datasets by their ID
      const groupedDatasets = this.dashboardChart.chartDataResponse.datasets.reduce((groups: { [key: string]: any[] }, ds: any) => {
        const id = ds.id; // Fallback to label if no ID
        if (!groups[id]) {
          groups[id] = [];
        }
        groups[id].push(ds);
        return groups;
      }, {});

      // Calculate statistics for each group
      this.datasetStatistics = Object.values(groupedDatasets).map(datasets => {
        // Combine all data points from datasets in the group
        const combinedData = datasets.reduce((allData: number[], ds: any) => {
          return allData.concat(ds.data.map((value: any) => Number(value)));
        }, []);

        const total = combinedData.reduce((sum: number, value: number) => sum + value, 0);
        const average = total / (this.dashboardChart.chartDataResponse.labels.length * datasets.length);
        
        // Use the first dataset in group for properties
        const representative = datasets[0];
        const foundDataset = this.dashboardChart.chartDataResponse.datasets.find(ds => ds.id === representative.id);
        
        return {
          label: foundDataset?.parentLabel || 'Unnamed Dataset',
          totalResults: Number(total.toFixed(2)),
          averageResultsPerTimeUnit: this.dashboardChart.GroupBy !== 'metric' ? Number(average.toFixed(2)) : 0,
          targetType: foundDataset?.targetType || 'Unknown'
        };
      });
    }

    AddNewChartDataSet() {
        const newIndex = this.dashboardChart.dataSetRequests.length;
        const usedColors = this.dashboardChart.dataSetRequests.map(ds => ds.DisplayColor);
        const availableColor = this.dashboardChart.colors.find(color => !usedColors.includes(color)) || this.dashboardChart.colors[0];
        
        this.dashboardChart.dataSetRequests.push({
          Label: `Dataset ${newIndex + 1}`,
          ChartType: 'bar' as ChartType,
          AggregateType: 'sum' as AggregateType,
          TargetType: 'ParkingSession',
          ReportDateGrouping: 'Concurrent',
          Filters: new Array<DataSetRequestFilter>(),
          DisplayColor: availableColor
        });
    
        // If we now have more than one dataset, update grouping settings
        if (this.dashboardChart.dataSetRequests.length > 1 && this.dashboardChart.GroupBy === 'metric') {
          this.dashboardChart.GroupBy = 'd';
        }
        // Open the edit modal for the newly added dataset
        this.OpenChartEditModal(this.dashboardChart.dataSetRequests[newIndex], newIndex, true);
    }

    RemoveDataSet(index: number) {
      this.dashboardChart.dataSetRequests.splice(index, 1);
      this.dashboardChart.chartStateHandler.saveState();
      this.chartUpdated.emit({requests: this.dashboardChart.dataSetRequests, updateYAxis: true});
      this.dashboardChart.title = this.generateChartTitle();
    }

    HandleDateRangePreset() {
      const now = new Date();
      switch (this.dashboardChart.selectedDateRange) {
        case 'today':
          const today = new Date();
          this.dashboardChart.dateRange.start = new Date(today.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(today.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = true;
          break;
        case 'yesterday': {
          const yesterday = new Date(now);
          yesterday.setDate(yesterday.getDate() - 1);
          this.dashboardChart.dateRange.start = new Date(yesterday.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(yesterday.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = false;
          break;
        }
        case 'thisWeek': {
          const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
          this.dashboardChart.dateRange.start = new Date(firstDayOfWeek.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(lastDayOfWeek.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = true;
          break;
        }
        case 'lastWeek': {
          const lastWeekStart = new Date(now);
          lastWeekStart.setDate(lastWeekStart.getDate() - lastWeekStart.getDay() - 7);
          const lastWeekEnd = new Date(lastWeekStart);
          lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
          this.dashboardChart.dateRange.start = new Date(lastWeekStart.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(lastWeekEnd.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = false;
          break;
        }
        case 'thisMonth': {
          const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          this.dashboardChart.dateRange.start = new Date(firstDayOfMonth.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(lastDayOfMonth.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = true;
          break;
        }
        case 'lastMonth': {
          const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          const lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
          this.dashboardChart.dateRange.start = new Date(firstDayLastMonth.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(lastDayLastMonth.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = false;
          break;
        }
        case 'last6Months': {
          const now = new Date();
          const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
          this.dashboardChart.dateRange.start = new Date(sixMonthsAgo.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date().toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = true;
          break;
        }
        case 'thisYear': {
          const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
          const lastDayOfYear = new Date(now.getFullYear(), 11, 31);
          this.dashboardChart.dateRange.start = new Date(firstDayOfYear.setHours(0, 0, 0, 0)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(lastDayOfYear.setHours(23, 59, 59, 999)).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = true;
          break;
        }
        case 'lastYear': {
          const lastYear = now.getFullYear() - 1;
          this.dashboardChart.dateRange.start = new Date(lastYear, 0, 1).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.dateRange.end = new Date(lastYear, 11, 31, 23, 59, 59, 999).toLocaleString('sv').replace(' ', 'T');
          this.dashboardChart.isLive = false;
          break;
        }
        case 'custom':
          this.dashboardChart.isLive = false;
          break;
      }
      
      if (this.dashboardChart.selectedDateRange !== 'custom') {
        this.DateRangeChange();
      }

      if (this.dashboardChart.chartConfig) {
        this.dashboardChart.chartConfig.selectedDateRange = this.dashboardChart.selectedDateRange;
        this.dashboardChart.chartStateHandler.saveState();
      }
    } 

    DateRangeChange() {
      if (this.dashboardChart.chartConfig?.isFullPage) {
        this.dashboardChart.chartConfig.startDate = this.dashboardChart.dateRange.start;
        this.dashboardChart.chartConfig.endDate = this.dashboardChart.dateRange.end || undefined;
        this.dashboardChart.chartStateHandler.saveState();
      } 
      this.chartUpdated.emit({requests: this.dashboardChart.dataSetRequests, updateYAxis: false});
      this.dashboardChart.userInterfaceHandler.UpdateOrCheckIsLive();
    }

    SetGroupBy(grouping: GroupByOption) {
      this.dashboardChart.GroupBy = grouping;
      if (this.dashboardChart.chartConfig) {
        this.dashboardChart.chartConfig.groupBy = grouping;
      }
      this.chartUpdated.emit({requests: this.dashboardChart.dataSetRequests, updateYAxis: false});
    }

    SetBucketSize(event: any) {
      this.dashboardChart.bucketSize = event.target.value;
      this.chartUpdated.emit({requests: this.dashboardChart.dataSetRequests, updateYAxis: false});
    }

    UpdateStackedOption(stacked: boolean): void {
      this.dashboardChart.stacked = stacked;
      if (this.dashboardChart.chartConfig) {
        this.dashboardChart.chartConfig.stacked = stacked;
        this.dashboardChart.chartStateHandler.saveState();
      }
      // Trigger any necessary chart updates
      if (this.dashboardChart.chartDataResponse) {
        // Create new references to force Chart.js to update
        this.dashboardChart.chartDataResponse = {
          ...this.dashboardChart.chartDataResponse,
          datasets: [...this.dashboardChart.chartDataResponse.datasets],
          stacked: stacked
        };
      }
    }

    /**
     * Opens the edit modal for a given data set.
     * @param chart - The data set request to edit.
     * @param index - The index of the data set in the dashboard.
     */
    async OpenChartEditModal(chart: DataSetRequest, index: number, isNew: boolean = false) {
      const wasMetric = this.dashboardChart.GroupBy === 'metric';
      // Store reference to this
      const self = this;
      
      this.modalService.addModal(ChartEditModalComponent, {
        DataSetRequest: { ...chart },
        TotalDatasets: this.dashboardChart.dataSetRequests.length
      }).subscribe((result: any) => {
        if (result) { 
          this.dashboardChart.dataSetRequests[index] = result;
          
          if (wasMetric){
            if(this.dashboardChart.dataSetRequests.length === 1) {
              const targetType = this.dashboardChart.dataSetRequests[0]?.Metric;
              var property = this.reportingService.getReportableProperty(this.dashboardChart.dataSetRequests[0]?.TargetType,  targetType);  
              if(!property?.CanGroupBy) {
                self.SetGroupBy('d');
              }
            }
          }
          
          this.chartUpdated.emit({requests: this.dashboardChart.dataSetRequests, updateYAxis: true});
          this.dashboardChart.title = this.generateChartTitle();
        } else if (isNew) {
          // Remove the dataset if it's new and the modal was closed without saving
          this.dashboardChart.dataSetRequests.splice(index, 1);
          this.chartUpdated.emit({requests: this.dashboardChart.dataSetRequests, updateYAxis: false});
        }
      });
    }

    getStatisticsForChart(label: string | undefined, targetType: string | undefined) {
      return this.datasetStatistics.find(s => s.label === label && s.targetType === targetType) || {
        label: label || '',
        displayColor: '',
        totalResults: 0,
        averageResultsPerTimeUnit: 0
      };
    }
}