import { getLocaleNumberFormat } from '@angular/common';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoginServiceBase } from '../auth/login.service.base';
import { LoginService } from '../auth/login.service';
import { Geo } from '../util/geo';
import { ApiServiceBase } from './api.service.base';
import { ApiService } from './api.service';
import { threadId } from 'worker_threads';
import { R3PartialDeclaration } from '@angular/compiler';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  @Output()
  public AvailableOrganizationsChanged = new EventEmitter<any>();
  @Output()
  public OrganizationSettingsLoaded = new EventEmitter<any>();

  private Permissions = [{ Permission: "", PermissionLevel: 0 }];
  private Organizations: any[] = [];
  public CurrentOrganization: any = null;
  public OrgCurrencySymbol: any = "$";
  public OrgCurrencyCode: any = "USD";
  public Settings: any[] = [];
  public MeasurementSystem: string = "";

  constructor(private apiService: ApiServiceBase, private loginService: LoginServiceBase) {
    loginService.LoginStatusChanged.subscribe(loggedIn => {
      if (loggedIn == true) {
        this.RefreshAvailableOrganizations().then(result => {
        });
      }
      else {
        this.Organizations = [];
      }
    });
    loginService.OrganizationChanged.subscribe(orgId => {
      this.GetOrganization(orgId).then((x: any) => {
        if(x == null){ //the newly selected org is not in the list
          this.RefreshAvailableOrganizations().then(result => {
            this.GetOrganization(orgId).then((x: any) => {
              this.SetOrganization(x);
            });
          });
        }
        else{
          this.SetOrganization(x);
        }
      });
    })
    if (loginService.IsLoggedIn()) {
      this.RefreshAvailableOrganizations().then(result => {
        loginService.OrganizationDeleted.subscribe(x => {
          if(x == true){
            this.RefreshAvailableOrganizations().then(res => {
              loginService.DoOrganizationSwitch(this.Organizations[0].Id);
            });
          }
        })
      });
    }
  }

  public GetOrganizationSettings(){
    this.apiService.Get("features/installed/settings").then((result: any) => {
      if(result != null){
        let settings = result;
        if(settings.length > 0)
          this.Settings = result
            .filter((s: any) => s?.Settings) // Filter out null/undefined Settings arrays
            .flatMap((s: any) => s.Settings);
        else
          this.Settings = [];
        let measure = this.Settings.filter(x => x.SettingName == "MeasurementSystem");
        if(measure.length > 0)
          this.MeasurementSystem = measure[0]?.Value;
        else
          this.MeasurementSystem = "Metric";
        }
        this.OrganizationSettingsLoaded.emit(this.Settings);
    });
  }

  private SetOrganization(org : any){
    this.OrgCurrencySymbol = org.CurrencySymbol;
    this.OrgCurrencyCode = org.CurrencyCode;
    this.CurrentOrganization = org;
    //fetch organizationsettings
    this.GetOrganizationSettings();
  }

  public GetSetting(settingName: string): Promise<any> {
    return new Promise((resolve) => {
      if (!this.Settings || this.Settings.length === 0) {
        // Subscribe to settings loaded event and resolve when available
        this.OrganizationSettingsLoaded.subscribe((settings) => {
          const setting = settings.filter((x: any) => x.SettingName === settingName);
          resolve(setting.length > 0 ? setting[0].Value : null);
        });
        // Trigger settings fetch
        this.GetOrganizationSettings();
      } else {
        // Settings already available
        const setting = this.Settings.filter(x => x.SettingName === settingName);
        resolve(setting.length > 0 ? setting[0].Value : null);
      }
    });
  }

  public KMorMiles(){
    switch(this.MeasurementSystem){
      case "Metric":
        return "km";
      case "Imperial":
        return "mi";
    }
    return "";
  }

  public GetOrganization(id?: string | null) : Promise<any> {
    let promise : Promise<any> = new Promise((resolve, reject) => {
      if(this.Organizations.length == 0){
        this.RefreshAvailableOrganizations().then(result => {
          let o = this.GetOrganizationById(id);
          resolve(o);
        });
      }
      else{
        let o = this.GetOrganizationById(id);
        this.SetOrganization(o);
        resolve(o);
      }
    });
    return promise;
  }

  private GetOrganizationById(id? : string | null) : any{
    if (id == null) {
      if (this.CurrentOrganization != null && this.CurrentOrganization.Id == this.loginService.CurrentOrganizationId()) {
        return this.CurrentOrganization;
      }
      let orgId = this.loginService.CurrentOrganizationId();
      let org = this.Organizations.filter((x: any) => x.Id == orgId)[0];
      return org;
    }
    else {
      let org = this.Organizations.filter(x => x.Id == id);
      if (org.length > 0)
        return org[0];
    }
  }

  public GetAvailableOrganizations() {
    return this.Organizations;
  }



  public GetOrganizationName(id?: string | null) : Promise<any> {
    return new Promise<string>((resolve, reject) => {
      this.GetOrganization(id).then((x: any) => {
        resolve(x.Name);
      });
    });
  }

  public GetOrganizationLocation(id?: string | null) : Promise<any>{
    return new Promise<number[]>((resolve, reject) => {
      this.GetOrganization(id).then((org :any) => {
        if (org == null || org.LocationPoints == null){
          resolve([0, 0]);
        }
        if (org.LocationPoints != null)
          resolve(org.LocationPoints);
        resolve([0, 0]);
      });
    });
  }

  public GetCurrencySymbol(): string {
    return this.OrgCurrencySymbol;
  }

  public GetCurrencyCode(): string {
    return this.OrgCurrencyCode;
  }

  public GetLocale(): string {
    return "en-US";
  }

  public GetOrganizationDefaultPolygon(id?: string | null) : Promise<any> {
    return new Promise<number[][]>((resolve, reject) => {
      this.GetOrganization(id).then((x :any) => {
        let org = x;
        if (org.LocationPoints != null) {
          resolve(Geo.NewPolygonAtCenter(org.LocationPoints, 0.001));
        }
        resolve([[0, 0]]);
      });
    });
  }

  public GetTimeZoneName(id?: string | null) : Promise<any> {
    return new Promise<string>((resolve, reject) => {
      if(id == null && this.CurrentOrganization.TimeZoneDisplayName != null){
        resolve(this.CurrentOrganization.TimeZoneDisplayName);
      }
      else if(id != null){
        this.GetOrganization(id).then((x : any) => {
          resolve(x.TimeZoneDisplayName);
        });
      }
      else{
        resolve("");
      }
    });
  }

  public GetServiceBusListenerConnectionString(id?: string | null): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      this.GetOrganization(id).then((x: any) => {
        resolve(x.ServiceBusListenerConnectionString)
      });
    });
  }
  
  private RefreshPromise : Promise<any> | null = null;
  public RefreshAvailableOrganizations() {
    if(this.RefreshPromise != null)
      return this.RefreshPromise;
    this.RefreshPromise = new Promise<any>((resolve, reject) => {
      this.apiService.Get<any>("user/organizations").then(result => {
        this.Organizations = result;
        this.AvailableOrganizationsChanged.emit();
        resolve(result);
        this.RefreshPromise = null;
      });
    });
    return this.RefreshPromise;
  }
}
