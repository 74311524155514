<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>
<app-field Label="First Name">
    <div class="control">
        <input formControlName="FirstName" class="input" type="text" />
        <app-validator [For]="this.Form.get('FirstName')">
        </app-validator>
    </div>
</app-field>
<app-field Label="Last Name">
    <div class="control">
        <input formControlName="LastName" class="input" type="text" />
        <app-validator [For]="this.Form.get('LastName')">
        </app-validator>
    </div>
</app-field>
<app-field Label="Email">
    <div class="control">
        <input formControlName="Email" class="input" type="text" />
        <app-validator [For]="this.Form.get('Email')">
        </app-validator>
    </div>
</app-field>
<app-field Label="Account Balance">
    <div class="control">
        <app-currency-input formControlName="AccountBalance" [Disabled]="true">
        </app-currency-input>
        <app-validator [For]="Form.get('AccountBalance')"></app-validator>
    </div>
</app-field>
<app-field Label="Magic Money Balance">
    <div class="control">
        <app-currency-input formControlName="MagicMoneyAccountBalance" [Disabled]="true">
        </app-currency-input>
        <app-validator [For]="Form.get('MagicMoneyAccountBalance')"></app-validator>
    </div>
</app-field>
<app-field Label="Admin Panel Access">
    <div class="control">
        <app-toggleswitch formControlName="IsAdmin"></app-toggleswitch>
    </div>
</app-field>
<app-field Label="Permissions">
    <app-tabs>
        <app-tab Title="End User Roles">
            <app-tree formArrayName="Roles">
                <app-treenoderecursive [recursiveList]="endUserRoles" (UpdateItem)="UpdateRoleSelectedItem($event)">
                </app-treenoderecursive>
            </app-tree>
        </app-tab>
        <app-tab Title="Admin Roles" *ngIf="Form.get('IsAdmin')?.value == true">
            <app-tree formArrayName="Roles">
                <app-treenoderecursive [recursiveList]="adminRoles" (UpdateItem)="UpdateRoleSelectedItem($event)">
                </app-treenoderecursive>
            </app-tree>
        </app-tab>
        <app-tab Title="Granted Permissions" *ngIf="Form.get('IsAdmin')?.value == true">
            <div class="columns">
                <div class="column is-5">
                    <div style="margin-bottom:10px;">
                        <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
                        <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
                        <a (click)="ExpandSelected()" >Expand Selected</a></span>
                    </div>
                    <app-tree formArrayName="AdminPermissions">
                        <app-treenoderecursive [recursiveList]="features" (ItemClicked)="ClickItem($event)" (UpdateItem)="UpdateExplicitAdminSelectedItem($event)" (UpdateItemSelectedValue)="UpdateAdminItemSelectedValue($event)">
                        </app-treenoderecursive>
                    </app-tree>
                </div>
                <div class="column is-7">
                    <div class="box content" style="height:100%">
                        <h3 class="has-text-weight-bold">{{SelectedFeatureName}}</h3>
                        <p class="has-text-weight-light- is-italic">{{SelectedFeatureDefinition}}</p>
                        <h6 *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">Enables Menu Items</h6>
                        <ul *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">
                          <li *ngFor="let i of SelectedFeatureRoutes">
                            {{i.MenuPath}}
                          </li>
                        </ul>
                        <h6 *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0">Feature Prerequisites</h6>
                        <ul *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0"> 
                          <li *ngFor="let i of SelectedFeaturePrerequisites">
                            {{i.Name}}
                          </li>
                        </ul>
                      </div>
                </div>
            </div>
        </app-tab>
        <app-tab Title="End User Permissions" *ngIf="endUserfeatures != null && endUserfeatures.length > 0">
            <app-tree formArrayName="AdminPermissions">
                <app-treenoderecursive [recursiveList]="endUserfeatures" (UpdateItem)="UpdateEndUserSelectedItem($event)" (UpdateItemSelectedValue)="UpdateEndUserItemSelectedValue($event)">
                </app-treenoderecursive>
            </app-tree>
        </app-tab>
        <app-tab Title="Resulting Permission Set">
            <div style="margin-bottom:10px;">
                <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
                <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
                <a (click)="ExpandSelected()" >Expand Selected</a></span>
            </div>
            <app-tree>
                <app-treenoderecursive [recursiveList]="allfeatures" [NodeDisabled]="true">
                </app-treenoderecursive>
            </app-tree>
        </app-tab>
    </app-tabs>
</app-field>
