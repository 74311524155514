import { Component, OnInit, Type } from '@angular/core';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';
import { hardwaremessagemodalComponent } from '../../../shared/hardwaremessagemodal/hardwaremessagemodal.component';
import { Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent extends Busyable implements OnInit {
  public ModelId: any;
  public Filters: any;
  public Model: any;
  public HardwareModel: any = {};

  public StructureViewerLayers: StructureViewerLayer[] = [
    new StructureViewerLayer("ParkingLevel"), 
    new StructureViewerLayer("ParkingSpace"), 
    new StructureViewerLayer("GuidanceLightConfiguration", "Guidance Lights")
  ]

  public HardwareMessageType: Type<any> = hardwaremessagemodalComponent;

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("HID", "ChipId"),
    new Datalistviewcolumn("Gateway", "GatewayChipId"),
    new Datalistviewcolumn("Message", "MessageVersion"),
    new Datalistviewcolumn("Payload", "PayloadVersion"),
    new Datalistviewcolumn("Occurred", "OccurredOnLocalFriendlyString"),
    new Datalistviewcolumn("Sent", "SentOnLocalFriendlyString"),
    new Datalistviewcolumn("Processed", "ProcessedOnLocalFriendlyString"),
  ];

  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe(params => { this.ModelId = params['id']; });
    this.apiService.Get<any>("infrastructure/spaceconfigurations/" + this.ModelId).then(result => {
      this.Model = result;
      if (this.Model.Device != null){
        this.HardwareModel = this.Model.Device;
      }
      this.HardwareModel.GlobalLastCheckIn = '-';
      if (this.HardwareModel.LastCheckedInOn != null) {
        this.HardwareModel.GlobalLastCheckIn = this.HardwareModel.LastCheckedInOn;
      }
      if (this.HardwareModel.LastNonCarCheckInOn != null && (this.HardwareModel.GlobalLastCheckIn == '-' || this.HardwareModel.LastNonCarCheckInOn > this.HardwareModel.GlobalLastCheckIn)) {
        this.HardwareModel.GlobalLastCheckIn = this.HardwareModel.LastNonCarCheckInOn;
      }
      this.Model.DeviceAssignments.sort((a :any, b:any) => { 
        if (a.DateCreated < b.DateCreated) return 1;  
        else if (a.DateCreated > b.DateCreated) return -1;
        else return 0;
      });
      this.CreateDetailsData();
      this.StopLoading();
    });
  }

  CreateDetailsData(): void {
    this.Model.DayTime = this.Model.StartOfDayTime + ' - ' + this.Model.EndOfDayTime;
    this.Model.TotalSleepTime = this.Model.SleepTime + ' - ' + this.Model.WakeUpTime;
  }
}
