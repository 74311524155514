<app-loader [Parent]="this"></app-loader>
<div *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <button *ngIf="Model.ViolationStatus === 'Pending' || Model.ViolationStatus === 'Vacated without Action'" class="button is-primary" 
                (click)="openActionViolationModal()"
                [disabled]="Model.ViolationStatus === 'Actioned'">
        Action Violation
        </button>
    </app-pageheader>

    <div class="columns is-multiline">
        <div class="column is-6">
          <article class="panel" style="min-height: 30vh;">
            <p class="panel-heading">
              Violation Details
            </p>
            <a class="panel-block">
                <span class="label">Start Date</span>
                <span class="value">{{Model.ParkingStartDateLocalFriendlyString}}</span>
            </a>
            <a class="panel-block">
                <span class="label">Violation began</span>
                <span class="value">{{Model.StartDateLocalFriendlyString}}</span>
            </a>
            <a class="panel-block" *ngIf="Model.EndDateLocalFriendlyString">
                <span class="label">Violation ended</span>
                <span class="value">{{Model.EndDateLocalFriendlyString || 'N/A'}}</span>
            </a>
            <a class="panel-block" *ngIf="Model.DurationFriendlyString">
                <span class="label">Duration</span>
                <span class="value">{{Model.DurationFriendlyString || 'N/A'}}</span>
            </a>
            <a class="panel-block">
                <span class="label">Status</span>
                <span class="value">
                    <span class="tag" style="color:white;" [style.background-color]="enforcementService.getViolationStatusColor(Model.ViolationStatus)">{{Model.ViolationStatus}}</span>
                    <span class="tag is-info ml-2" *ngIf="Model.ViolationStatus === 'Actioned'">Actioned by: {{Model.ActionedByUserName}} on {{Model.ActionedDateLocalFriendlyString}}</span>
                </span>
            </a>
            <a class="panel-block">
              <span class="label">Location</span>
              <span class="value">{{Model.ParkingSpaceLocationString}}</span>
            </a>
            <a class="panel-block" *ngIf="Model.ParkingSessionId">
                <span class="label">Parking Session</span>
                <span class="value">
                    <a *ngIf="Model.ParkingSessionId" routerLink="/parking/parkingsessions/{{Model.ParkingSessionId}}">{{Model.ParkingSessionId}}</a>
                    <span *ngIf="!Model.ParkingSessionId">N/A</span>
                </span>
            </a>
            <a class="panel-block" *ngIf="Model.PlateNumber">
              <span class="label">Plate Number</span>
              <span class="value">{{Model.PlateNumber}}</span>
            </a>
            <a class="panel-block" *ngIf="Model.TicketReference">
                <span class="label">Ticket Reference</span>
                <span class="value">
                    <span class="tag"  style="color:black;">Assigned Ticket #{{Model.TicketReference}}</span>
                </span>
            </a>
          </article>
        </div>
        <div class="column is-6">
            <app-structureviewermaps [Layers]="StructureViewerLayers" [SearchTerm]="StructureSearchTerm" 
            [StructureId]="StructureId" [StructureViewerForType]="StructureViewerForType"></app-structureviewermaps>
        </div>
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                  Notes
                </p>
                <div class="panel-block">
                  {{Model.Notes ?? 'No notes available'}}
                </div>
            </article>
        </div>
    </div>
</div>