<app-tabs>
    <app-tab Title="Designer">
        <div class="mapeditor-outer" [formGroup]="Form">
            <div class="mapeditor-toolbox">
                <div class="toolbar-item">
                    <button class="button is-primary" (click)="SetScale(ViewScale*.95)">
                        <i class="fa fa-minus"></i>
                    </button>
                </div>
                <div class="toolbar-item">
                    <button class="button is-primary" (click)="SetScale(ViewScale*1.05)">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
                <div class="toolbar-item">
                    <button class="button is-default" (click)="Snapping = !Snapping">
                        <i class="fa fa-magnet" *ngStyle="{'color': Snapping ? '000000' : '333333'}"></i>
                        Snapping: {{Snapping?'on' : 'off'}}
                    </button>
                </div>
                <div class="toolbar-item">
                    <div class="dropdown" #tooldropdown (click)="tooldropdown.classList.toggle('is-active')">
                        <div class="dropdown-trigger">
                            <button class="button is-default" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Load</span>
                                <span class="icon is-small">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a class="dropdown-item" (click)="ImportLevel()">
                                    Copy existing Level
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="toolbar-item" *ngFor="let t of Tools">
                    <button *ngIf="t.Tool != null" class="button" (click)="SelectTool(t)">{{t.Name}}</button>
                    <div *ngIf="t.Tools != null" class="dropdown" #tooldropdown
                        (click)="tooldropdown.classList.toggle('is-active')">
                        <div class="dropdown-trigger">
                            <button class="button is-default" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>{{t.Name}}</span>
                                <span class="icon is-small">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a class="dropdown-item" *ngFor="let s of t.Tools" (click)="SelectTool(s)">
                                    {{s.Name}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mapeditor-inner" tabindex="0" #DesignSurface (mousemove)="DesignSurfaceMouseMove($event)"
                (mouseup)="DesignSurfaceMouseUp($event)" (mouseleave)="DesignSurfaceMouseUp($event)"
                (mousedown)="DesignSurfaceMouseDown($event)" (click)="DesignSurfaceClick($event)"
                (keydown)="DesignSurfaceKeyPress($event)" (wheel)="DesignerMouseWheel($event)">
                <div class="design-surface" [ngStyle]="{'cursor' : DragType == 'pan' ? 'pointer' : 'auto' }">
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('ParkingLevel')">
                        <path [attr.d]="i.path" stroke="black" stroke-width="1" (click)="ItemClicked($event,i)"
                            (mousedown)="ItemMouseDown($event, i)">
                        </path>
                        <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                            [attr.y]="ViewScale*(i.Top)+12">
                            {{i.FormControl.get('Name')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('ParkingRow')">
                        <defs>
                            <pattern id="rowHatch" width="8" height="8" patternUnits="userSpaceOnUse"
                                patternTransform="rotate(45)">
                                <rect width="4" height="8" transform="translate(0,0)" fill="#aaa"></rect>
                            </pattern>
                        </defs>
                        <path [attr.d]="i.path" stroke="black" stroke-width="1"
                            style="fill:url(#rowHatch); opacity: 0.1" (click)="ItemClicked($event,i)"
                            (mousedown)="ItemMouseDown($event, i)">
                        </path>
                        <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                            [attr.y]="ViewScale*(i.Top)+12">
                            {{i.FormControl.get('Name')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('Gate')">
                        <defs>
                            <pattern id="diagonalHatch" width="8" height="8" patternUnits="userSpaceOnUse"
                                patternTransform="rotate(45)">
                                <rect width="4" height="8" transform="translate(0,0)" fill="green"></rect>
                            </pattern>
                        </defs>
                        <path [attr.d]="i.path" stroke="black" stroke-width="1"
                            style="fill:url(#diagonalHatch); opacity: 0.1;" (click)="ItemClicked($event,i)"
                            (mousedown)="ItemMouseDown($event, i)" (dblclick)="EditGate(i);">
                        </path>
                        <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                            [attr.y]="ViewScale*(i.Top)+12">
                            {{i.FormControl.get('Name')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('Lane')">
                        <defs>
                            <pattern id="laneHatch" width="8" height="8" patternUnits="userSpaceOnUse"
                                patternTransform="rotate(45)">
                                <rect width="4" height="8" transform="translate(0,0)" fill="purple"></rect>
                            </pattern>
                        </defs>
                        <path [attr.d]="i.path" stroke="black" stroke-width="1"
                            style="fill:url(#laneHatch); opacity: 0.2;" (click)="ItemClicked($event,i)"
                            (mousedown)="ItemMouseDown($event, i)" (dblclick)="EditLane(i);">
                        </path>
                        <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                            [attr.y]="ViewScale*(i.Top)+12">
                            {{i.FormControl.get('Name')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('ParkingSpace')">
                        <path [attr.d]="i.path" [attr.style]="'fill:' + i.GetFillColor() + '; fill-opacity:0.4'"
                            [attr.stroke]="i.GetStrokeColor()" stroke-width="1" (click)="ItemClicked($event,i)"
                            (mousedown)="ItemMouseDown($event, i)">
                        </path>
                        <g [innerHTML]="i.GetImage(i.Top*ViewScale, i.Height*ViewScale, i.Left*ViewScale)"
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                            <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                [attr.y]="ViewScale*(i.Top)">
                                {{i.FormControl.get('Name')?.value}}
                            </text>
                        </g>
                        <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left + 3"
                            [attr.font-size]="0.001*ViewScale" [attr.y]="ViewScale*(i.Top)+(0.0012*ViewScale)">
                            {{i.FormControl.get('Name')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem">
                        <defs>
                            <pattern id="laneHatch" width="8" height="8" patternUnits="userSpaceOnUse"
                                patternTransform="rotate(45)">
                                <rect width="4" height="8" transform="translate(0,0)" fill="green"></rect>
                            </pattern>
                        </defs>
                        <g *ngFor="let i of MapItemsByType.get('Shape')">
                            <path [attr.d]="i.path" [attr.stroke]="'#' + i.FormControl.get('OutlineColor')?.value"
                                [attr.stroke-width]="i.FormControl.get('OutlineThickness')?.value"
                                [attr.fill]="'#' + i.FormControl.get('FillColor')?.value"
                                [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'opacity': i.FormControl.get('Opacity')?.value }"
                                (click)="ItemClicked($event,i)" (mousedown)="ItemMouseDown($event, i)">
                            </path>
                            <path [attr.d]="i.entryPath" stroke="blue"
                                stroke-width="5">
                            </path>
                            <path [attr.d]="i.exitPath" stroke="blue"
                            stroke-width="5">
                        </path>
                            <text
                                *ngIf="i.FormControl.get('Name') != null && i.FormControl.get('Type')?.value == 'BusinessDirectory'"
                                [attr.x]="ViewScale * i.Left + 3" [attr.font-size]="0.001*ViewScale"
                                [attr.y]="ViewScale*(i.Top)+(0.0012*ViewScale)" style="font-weight: bold;" wrap="hard">
                                {{GetBusinessDirectoryName(i.FormControl.get('BusinessDirectoryId')?.value)}}
                            </text>
                        </g>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('LocalController')">
                        <image href="/assets/levelmap/terminal.svg" [attr.x]="i.Left*ViewScale"
                            [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                            [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                            (dblclick)="EditLocalController(i);" (mousedown)="ItemMouseDown($event, i)"
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }" />
                        <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                            [attr.y]="ViewScale*(i.Top)">
                            {{i.FormControl.get('Name')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('Camera')">
                        <image href="/assets/levelmap/camera.svg" [attr.x]="i.Left*ViewScale" [attr.y]="i.Top*ViewScale"
                            [attr.height]="i.Height*ViewScale" [attr.width]="i.Width*ViewScale"
                            (click)="ItemClicked($event,i)" (dblclick)="EditCamera(i);"
                            (mousedown)="ItemMouseDown($event, i)"
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }" />
                        <text [attr.x]="ViewScale * i.Left" [attr.y]="ViewScale*(i.Top)">
                            {{i.FormControl.get('LocalIpAddress')?.value}}
                        </text>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('Barrier')">
                        <g
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                            <image href="/assets/levelmap/barrier.svg" [attr.x]="i.Left*ViewScale"
                                [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                                [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                                (dblclick)="EditBarrier(i);" (mousedown)="ItemMouseDown($event, i)" />
                            <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                [attr.y]="ViewScale*(i.Top)">
                                {{i.FormControl.get('Name')?.value}}
                            </text>
                        </g>
                    </svg>
                    <svg class="mapitem {{i.ClassName}}" *ngFor="let i of MapItemsByType.get('TrafficLight')">
                        <g
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                            <image href="/assets/levelmap/trafficlight.svg" [attr.x]="i.Left*ViewScale"
                                [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                                [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                                (dblclick)="EditTrafficLight(i);" (mousedown)="ItemMouseDown($event, i)" />
                            <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                [attr.y]="ViewScale*(i.Top)">
                                {{i.FormControl.get('Name')?.value}}
                            </text>
                        </g>
                    </svg>
                    <svg class="mapitem" *ngFor="let i of MapItemsByType.get('GatewayConfiguration')">
                        <g
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                            <image href="/assets/levelmap/gateway.svg" [attr.x]="i.Left*ViewScale"
                                [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                                [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                                (dblclick)="EditGatewayConfiguration(i);" (mousedown)="ItemMouseDown($event, i)" />
                            <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                [attr.y]="ViewScale*(i.Top)">
                                {{i.FormControl.get('Name')?.value}}
                            </text>
                        </g>
                    </svg>
                    <svg class="mapitem" *ngFor="let i of MapItemsByType.get('CarCounterConfiguration')">
                        <g
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                            <image href="/assets/levelmap/carcounter.svg" [attr.x]="i.Left*ViewScale"
                                [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                                [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                                (dblclick)="EditCarCounterConfiguration(i);" (mousedown)="ItemMouseDown($event, i)" />
                            <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                [attr.y]="ViewScale*(i.Top)">
                                {{i.FormControl.get('Name')?.value}}
                            </text>
                        </g>
                    </svg>
                    <svg class="mapitem" *ngFor="let i of MapItemsByType.get('SignConfiguration')">
                        <g
                            [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                            <image href="/assets/levelmap/sign.svg" [attr.x]="i.Left*ViewScale"
                                [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                                [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                                (dblclick)="EditSignConfiguration(i);" (mousedown)="ItemMouseDown($event, i)" />
                            <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                [attr.y]="ViewScale*(i.Top)">
                                {{i.FormControl.get('Name')?.value}}
                            </text>
                        </g>
                    </svg>
                    <div *ngFor="let i of MapItemsByType.get('GuidanceLightConfiguration')">
                        <svg class="mapitem">
                            <g [ngStyle]="{ 'transform': 'rotate(' + i.DisplayAngle + 'deg)', 'transform-origin': i.Center[0]*ViewScale + 'px ' + i.Center[1]*ViewScale + 'px' }">
                                <image href="/assets/levelmap/guidancelight.svg" [attr.x]="i.Left*ViewScale"
                                    [attr.y]="i.Top*ViewScale" [attr.height]="i.Height*ViewScale"
                                    [attr.width]="i.Width*ViewScale" (click)="ItemClicked($event,i)"
                                     (mousedown)="ItemMouseDown($event, i)" />
                                <text *ngIf="i.FormControl.get('Name') != null" [attr.x]="ViewScale * i.Left"
                                    [attr.y]="ViewScale*(i.Top)">
                                    {{i.FormControl.get('Name')?.value}}
                            </text>
                            </g>
                        </svg>
                    </div>
                    <svg class="mapitem">
                        <g *ngFor="let i of SelectedItems">
                            <g *ngIf="i.Resizable">
                                <circle *ngFor="let point of i.BisectPoints; let ix = index;"
                                    [attr.cx]="point[0]*ViewScale" [attr.cy]="point[1]*ViewScale" r="6" stroke="black"
                                    fill="#aaa" (mousedown)="BisectionPointMouseDown($event, point, i, ix)">
                                </circle>
                                <circle *ngFor="let point of i.PolygonPoints; let ix = index;"
                                    [attr.cx]="point[0]*ViewScale" [attr.cy]="point[1]*ViewScale" r="8" stroke="black"
                                    fill="red" (mousedown)="PointMouseDown($event, i, point, ix)">
                                </circle>
                            </g>
                        </g>
                        <g *ngIf="SelectionBounds != null">
                            <circle
                                [attr.cx]="(SelectionBounds.Left + ((SelectionBounds.Right-SelectionBounds.Left)/2))*ViewScale"
                                [attr.cy]="(SelectionBounds.Bottom+0.001)*ViewScale" r="5" stroke="black" fill="green"
                                (mousedown)="RotateMouseDown($event)">
                            </circle>
                        </g>
                    </svg>
                    <svg class="mapitem marquee" *ngIf="Marquee != null">
                        <path stroke-dasharray="5,5" [attr.d]="Marquee" fill="none" stroke="blue" stroke-width="1"
                            style=" pointer-events: none;"></path>
                    </svg>
                </div>
            </div>

            <div class="mapeditor-properties">
                <div class="is-size-4">Properties</div>
                <div *ngIf="SelectedItems.length > 0" class="is-small">

                    <table class="table is-striped properties-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="SelectedItems.length > 0 && SelectedItems[0].ClassName === 'GuidanceLightConfiguration'">
                                <td>Id</td>
                                <td><input class="input" readonly [formControl]="SelectedItems[0].FormControl.get('Id')"/></td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('EditorLocked') != null">
                                <td>
                                    Locked
                                </td>
                                <td>
                                    <input type="checkbox" style="vertical-align: bottom;"
                                        [formControl]="SelectedItems[0].FormControl.get('EditorLocked')"
                                        (change)="UpdateSelectedItemsProperty('EditorLocked')">
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('Name') != null">
                                <td>
                                    Name
                                </td>
                                <td>
                                    <input class="input" type="text"
                                        [formControl]="SelectedItems[0].FormControl.get('Name')">
                                </td>
                            </tr>
                            <!-- We add this in, to show on street kiosks in the public facing app. These are the only ones with IsPublicFacing = true-->
                            <tr *ngIf="SelectedItems[0].FormControl.get('IsPublicFacing') != null && SelectedItems[0].FormControl.get('IsPublicFacing').value">
                                <td>
                                    MediaId
                                </td>
                                <td>
                                    <app-media-thumbnail *ngIf="SelectedItems[0].FormControl.get('MediaId').value != null" [MediaId]="SelectedItems[0].FormControl.get('MediaId').value" Title="Media Item"  ModalImageWidth="300px" ModalImageHeight="300px" ThumbnailHeight="60px" ThumbnailWidth="60px"></app-media-thumbnail>&nbsp;&nbsp;
                                    <app-mediaupload [MediaId]="SelectedItems[0].FormControl.get('MediaId').value" Type=".svg" (MediaIdChange)="MediaIdChanged($event)"></app-mediaupload>
                                    <button class="button is-primary is-small" *ngIf="MediaIdsMisMatch(SelectedItems[0].FormControl.get('MediaId').value)" (click)="LineUpMedia(SelectedItems[0].FormControl.get('MediaId').value)"> Line up all MediaIds</button>
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('LocalControllerId') != null">
                                <td>
                                    Controller
                                </td>
                                <td>
                                    <select class="input"
                                        [formControl]="SelectedItems[0].FormControl.get('LocalControllerId')"
                                        name="LocalControllerId">
                                        <option
                                            *ngFor="let c of ModelEditor.FormArray(ModelEditor.Form,'Controllers').controls"
                                            [ngValue]="c.get('Id')?.value">{{c.get('Name')?.value}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('Type')?.value == 'BusinessDirectory'">
                                <td>
                                    Business
                                </td>
                                <td>
                                    <app-businessselector
                                        [formControl]="SelectedItems[0].FormControl.get('BusinessDirectoryId')"
                                        [Business]="GetBusiness(SelectedItems[0].FormControl.get('BusinessDirectoryId')?.value)"
                                        (BusinessIdChange)="BusinessIdChange($event)" class="newclass">
                                    </app-businessselector>
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('OutlineColor') != null">
                                <td>
                                    Line
                                </td>
                                <td>
                                    <app-colorpicker [formControl]="SelectedItems[0].FormControl.get('OutlineColor')">
                                    </app-colorpicker>
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('FillColor') != null">
                                <td>
                                    Line
                                </td>
                                <td>
                                    <app-colorpicker [formControl]="SelectedItems[0].FormControl.get('FillColor')">
                                    </app-colorpicker>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Width (m)
                                </td>
                                <td>
                                    <input class="input" type="number" step="0.1"
                                        [value]="(SelectedItems[0].Width*1000).toFixed(1)"
                                        (change)="SetItemWidth($event)">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Height (m)
                                </td>
                                <td>
                                    <input class="input" type="number" step="0.1"
                                        [value]="(SelectedItems[0].Height*1000).toFixed(1)"
                                        (change)="SetItemHeight($event)">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Left
                                </td>
                                <td>
                                    <input class="input" type="number" step="0.1"
                                        [value]="(SelectedItems[0].Left*1000).toFixed(1)">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Top
                                </td>
                                <td>
                                    <input class="input" type="number" step="0.1"
                                        [value]="(SelectedItems[0].Top*1000).toFixed(1)">
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('ParkingSpaceTypeId') != null">
                                <td>
                                    Type
                                </td>
                                <td>
                                    <select class="input"
                                        [formControl]="SelectedItems[0].FormControl.get('ParkingSpaceTypeId')"
                                        name="ClassName">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let a of ParkingSpaceTypes" [ngValue]="a.Id"><img
                                                [src]="a.HtmlImage" />{{a.Name}}</option>
                                    </select>
                                </td>
                            </tr>

                            <tr *ngIf="SelectedItems[0].FormControl.get('ParkingSpaceTypeId') != null">
                                <td>
                                    Sensor
                                </td>
                                <td>
                                    <app-sensor-selector [formControl]="SelectedItems[0].FormControl.get('ChipId')"
                                        [Sensor]="InitialSensor">

                                    </app-sensor-selector>

                                </td>
                            </tr>

                            <tr *ngIf="SelectedItems[0].ClassName == 'ParkingSpace'">
                                <td>
                                    Guidance Light Assignment
                                </td>
                                <td>
                                    <div class="control select">
                                        <select class="input" [formControl]="SelectedItems[0].FormControl.get('GuidanceLightConfigurationId')" >
                                            <option *ngFor="let i of MapItemsByType.get('GuidanceLightConfiguration')" [value]="i.FormControl.get('Id')?.value">{{i.FormControl.get('Name')?.value}}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="SelectedItems[0].ClassName == 'ParkingSpace'">
                                <td>
                                    Port Number
                                </td>
                                <td>
                                    <input class="input" type="number" min="1" max="6" [formControl]="SelectedItems[0].FormControl.get('GuidanceLightPortNumber')">
                                </td>
                            </tr>
                            
                            <tr *ngIf="SelectedItems[0].ClassName == 'GatewayConfiguration'">
                                <td>
                                    Gateway
                                </td>
                                <td>
                                    <app-gateway-selector [formControl]="SelectedItems[0].FormControl.get('ChipId')"
                                        [Gateway]="InitialGateway">
                                    </app-gateway-selector>
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].ClassName == 'GatewayConfiguration'">
                                <td>
                                    APN
                                </td>
                                <td>
                                    <input class="input" type="text" [formControl]="SelectedItems[0].FormControl.get('Apn')" />
                                </td>
                            </tr>

                            <tr *ngIf="SelectedItems[0].ClassName == 'SignConfiguration'">
                                <td>
                                    Sign
                                </td>
                                <td>
                                    <app-sign-selector [formControl]="SelectedItems[0].FormControl.get('ChipId')"
                                        [Sign]="InitialSign">
                                    </app-sign-selector>
                                </td>
                            </tr>

                            <tr *ngIf="SelectedItems[0].ClassName == 'Barrier'">
                                <td>
                                    Commands
                                </td>
                                <td>
                                    <div class="p-1">
                                        <button type="button" class="button is-small is-primary"
                                            (click)="OpenBarrier(SelectedItems[0].FormControl.get('Id').value)">Open</button>
                                    </div>
                                    <div class="p-1">
                                        <button type="button" class="button is-small is-primary"
                                            (click)="KeepBarrierOpen(SelectedItems[0].FormControl.get('Id').value)">Keep
                                            Open</button>
                                    </div>
                                    <div class="p-1">
                                        <button type="button" class="button is-small is-primary"
                                            (click)="CloseBarrier(SelectedItems[0].FormControl.get('Id').value)">Close</button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('Type')?.value == 'Ramp' || 
                                       SelectedItems[0].FormControl.get('Type')?.value == 'BusinessDirectory' ||
                                       SelectedItems[0].FormControl.get('Type')?.value == 'Stairwell'">
                                <td>
                                    Entry Segment
                                </td>
                                <td>
                                    <input type="number" class="input" [formControl]="SelectedItems[0].FormControl.get('EntrySegment')" (change)="CalculateEntryPath($event)">
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('Type')?.value == 'Ramp' || 
                                       SelectedItems[0].FormControl.get('Type')?.value == 'BusinessDirectory'">
                                <td>
                                    Exit Segment
                                </td>
                                <td>
                                    <input type="number" class="input" [formControl]="SelectedItems[0].FormControl.get('ExitSegment')" (change)="CalculateExitPath($event)">
                                </td>
                            </tr>
                            <tr *ngIf="SelectedItems[0].FormControl.get('Type')?.value == 'Ramp'">
                                <td>
                                    Is Rising
                                </td>
                                <td>
                                    <input type="checkbox" [formControl]="SelectedItems[0].FormControl.get('IsRising')">
                                </td>
                            </tr>

                            <tr *ngIf="SelectedItems.length > 0 && SelectedItems[0].ClassName === 'GuidanceLightConfiguration'">
                                <td>
                                    Port / SpaceId
                                </td>
                                <td>
                                    <div class="columns" style="margin:10px;" *ngFor="let assignment of SelectedItems[0]?.FormControl.get('PortAssignments')?.controls">
                                        <div class="column is-6" style="padding:0px;">
                                            <input class="input" type="text" [formControl]="assignment.get('PortNumber')">
                                        </div>
                                        <div class="column is-6" style="padding:0px;">
                                            <div class="control select">
                                                <select class="input" [formControl]="assignment.get('ParkingSpaceId')" >
                                                    <option *ngFor="let i of MapItemsByType.get('ParkingSpace')" [value]="i.FormControl.get('Id')?.value">{{i.FormControl.get('Name')?.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="button is-primary" (click)="AddAssignment()">Add Assignment</button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </app-tab>
    <app-tab Title="Location">
        <app-field Label="Rotation">
            <div class="control">
                <ngx-slider [(value)]="GoogleMapRotation" [options]="{ floor: -180, ceil: 180 }"
                    (userChange)="UpdateGoogleMapPath(null,null)"></ngx-slider>
            </div>
        </app-field>
        <button (click)="CenterLevelOnMap()">Center</button>



        {{GoogleMapSpaces.length}} space polys

        <google-map width="100%" height="600" [center]="GoogleMapCenter" [zoom]="18">
            <map-polygon *ngFor="let s of GoogleMapSpaces" [paths]="s"
                [options]="{ draggable: false, strokeColor: '#ffffff', strokeWeight: 1 }"></map-polygon>
            <map-polygon [paths]="GoogleMapLevelPath" [options]="{ draggable: true, editable: false }"
                (polygonDragend)="GoogleMapLevelDragged($event)"></map-polygon>
        </google-map>

    </app-tab>
</app-tabs>