import { EventEmitter, Injectable, Output } from '@angular/core';
import { OrganizationsService } from './organizations.service';
import { ServiceBusClient } from '@azure/service-bus';
import { ApiService } from './api.service';
import { PermissionsService } from './permissions.service';
import { debug } from 'console';
import { LoginServiceBase } from '../auth/login.service.base';
import { DashboardChartComponent } from '../shared/charts/dashboardchart/dashboard-chart.component';


export interface ReportableProperty {
  CanCount: boolean;
  CanSum: boolean;
  CanAverage: boolean;
  CanMin: boolean;
  CanMax: boolean;
  CanGroupBy: boolean;
  CanSplitBy: boolean;
  Type: string;
  ColumnName: string;
  Label: string;
  GroupName: string;
  GroupByBucketRequired: boolean;
  GroupByBucketLabel: string;
}

export interface ReportableFilter {
  Label: string;
  ColumnName: string;
}

export interface ReportableEntity {
  Label: string;
  TargetType: string;
  IsPeriodEntity: boolean;
  Properties: ReportableProperty[];
  Filters: ReportableFilter[];
}

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  @Output()
  public TransactionsChanged = new EventEmitter<any>();
  public ReportableEntities: ReportableEntity[] | null = null;
  public Charts : DashboardChartComponent[] = [];
  private Client? : ServiceBusClient;

  constructor(private apiService : ApiService, private permissionService : PermissionsService, private organizationsService : OrganizationsService, private loginService : LoginServiceBase) {
    this.fetchReportableEntities();
    this.ConnectServiceBus();
  }

  public registerChart(chart: DashboardChartComponent) {
    // Prevent duplicate registration
    if (this.Charts.includes(chart)) {
      console.log("Chart already registered, skipping", chart);
      return;
    }
    
    if(this.Charts.length == 0 && chart.isLive){
      console.log("Connecting to Service Bus - first live chart added");
      this.ConnectServiceBus();
    }
    this.Charts.push(chart);
    console.log("Added chart to reporting service", this.Charts, "Service Bus Connected");
    console.log("Total number of registered charts:", this.Charts.length);
  }

  public deregisterChart(chart: DashboardChartComponent) {
    this.Charts = this.Charts.filter(x => x !== chart);
    console.log("Removed chart from reporting service", this.Charts);

    if(this.Charts.length === 0 || this.Charts.every(x => !x.isLive)) {
      console.log("Disconnecting from Service Bus - no live charts remaining");
      this.Client?.close();
      this.Client = undefined;
    }
    console.log("Service Bus Connected: false");
  }

  private async fetchReportableEntities() {
    this.apiService.Get(`/reporting/dashboard/entities`).then((response: any) => {
      this.ReportableEntities = response;
    });
  }


  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(x == null || x == ''){
        console.log("Cannot connect to Service Bus - no connection string");
        return;
      }
      if(this.Client != null){
        //if we were connected before then close
        console.log("Closing existing Service Bus connection");
        this.Client.close();
      }
  
     this.apiService.Post<any>("infrastructure/servicebus/topics/ReportingCharts/" + this.loginService.UserId() + this.permissionService.GetSessionIdentifier(), {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("ReportingCharts", this.loginService.UserId() + this.permissionService.GetSessionIdentifier());
      
      // Capture 'this' context
      // need to capture this context so that the message handler can access the charts array.
      const self = this;

      
      const SBMessageHandler = async (messageReceived:any) => {
        for (let key in messageReceived.applicationProperties) {
          messageReceived.applicationProperties[key.toLowerCase()] = messageReceived.applicationProperties[key];
        }
        // Convert Uint8Array to string and parse JSON

        console.log("Reporting Service Bus Message", messageReceived.body);
        // Update data array to match the expected format
        const data = messageReceived.body;

        console.log("Total number of registered charts:", this.Charts.length);
        for(const chart of this.Charts.filter(x => x.isLive)){
          this.updateChart(chart, data);
        }
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
    console.log("Service Bus connection established");
  }

  /**
   * Updates the chart with the new data.
   * @param chart - The chart to update.
   * @param data - The new data to update the chart with.
   */
  public updateChart(chart: DashboardChartComponent, data: any) {
    if(!chart.chart.IsLoading){
    var type = data.DataType;
    var increment = data.IncrementCount;
    var groupingLabel: string | null = null;

    if(chart.GroupBy === 'h') {
      groupingLabel = data.Year + "-" + data.Month + "-" + data.Day + " " + data.Hour + ":00";
    }
    else if(chart.GroupBy === 'd') {
      groupingLabel = data.Year + "-" + data.Month + "-" + data.Day;
    }
    else if(chart.GroupBy === 'w') {
      groupingLabel = data.Year + "-" + data.Week;
    }
    else if(chart.GroupBy === 'm') {
      groupingLabel = data.Year + "-" + data.Month;
    }
    else if(chart.GroupBy === 'y') {
      groupingLabel = data.Year;
    }

    if(groupingLabel != null) {
      var matchingLabelIndex = chart.chartDataResponse.labels.findIndex((label: any) => 
        label === groupingLabel
      );

      //label doesn't exist
      if(matchingLabelIndex == -1) {
        //if the chart is live, and the date range is not custom, then we have passed into the next time period.
        //so we need to handle the date range preset.
        if(chart.selectedDateRange !== 'custom' && chart.isLive) {
          chart.chartDataHandler.HandleDateRangePreset();
        }
        //if the chart is live, and the end date is null, then we are still in the current time period, so we can just add the new label.
        else if(chart.isLive && chart.dateRange.end === "") {
          chart.chartDataResponse.labels.push(groupingLabel);
          chart.chartDataResponse.datasets.forEach((dataset: any) => {
            dataset.data.push(0);
          });
        }
      }

      //Now we can be sure that the label exists, so we can update the data.
      matchingLabelIndex = chart.chartDataResponse.labels.findIndex((label: any) => 
        label === groupingLabel
      );


      // Get datasets to update - handle case where filters might be null/undefined
      var dataSetsToUpdate = chart.chartDataResponse.datasets.filter((dataset: any) => 
        (!dataset.filters || dataset.filters.length === 0 ) && dataset.targetType == type
      );

      //foreach dataset to update, update the data and the chart.
      for(const dataset of dataSetsToUpdate){
        dataset.data[matchingLabelIndex] = dataset.data[matchingLabelIndex] + Number(increment);
        chart.chart.updateSingleValue(chart.chartDataResponse.datasets.indexOf(dataset), matchingLabelIndex, dataset.data[matchingLabelIndex] + 1);
        chart.chartDataHandler.updateDatasetStatistics();
      }
    }
  }
  }

  /**
   * Fetches the report data for the chart.
   * @param chartToFind - The chart to fetch the data for - build the request based on the chart configuration.
   */
  public async fetchReportData(chart: DashboardChartComponent) {
    //find the data set request that matches the chart.
    if (chart && chart.chart) {
      if (chart.chart) {
        chart.chart.Loading();
      }

      if(chart.selectedDateRange !== 'custom' && (chart.dateRange.start === '' || chart.dateRange.end === '')) {
        chart.chartDataHandler.HandleDateRangePreset();
        return;
      }

      if(chart.dataSetRequests.length === 0){
        chart.toastr.info('Please add at least one data set');
        chart.chart.StopLoading();
        return;
      }
      
      if(chart.dateRange.start === ''){
        chart.toastr.info('Please select a start date');
        chart.chart.StopLoading();
        return;
      }

      if (chart.dateRange.end && new Date(chart.dateRange.start) > new Date(chart.dateRange.end)) {
        chart.toastr.info('Start date must be before end date');
        chart.chart.StopLoading();
        return;
      }
      
      try {
        const response = await this.apiService.Post(`/reporting/dashboard/report`, {
          StartDate: chart.dateRange.start,
          EndDate: chart.dateRange.end || new Date().toLocaleString(),
          GroupBy: chart.GroupBy,
          DataSets: chart.dataSetRequests,
          GroupByBucketSize: chart.GroupBy === 'metric' ? chart.bucketSize : undefined
        });
        
        chart.chartDataResponse = {
          datasets: (response as any).DataSets.flatMap((ds: any, dataSetIndex: number) => 
            ds.Data.map((data: any) => ({
              id: dataSetIndex,
              data: data.DataSet.map((value: any) => Number(value)),
              label: data.Label,
              type: ds.ChartType?.toLowerCase() || chart.chartType,
              displayColor: data.DisplayColor,
              targetType: ds.TargetType,
              stacked: chart.stacked,
              parentLabel: ds.Label
            }))
          ),
          labels: (response as any).XAxisLabels,
          stacked: chart.stacked
        };

        chart.chart.StopLoading();
        chart.lastUpdated = new Date();
        
        // Replace statistics calculation with method call
        chart.chartDataHandler.updateDatasetStatistics();

      } catch (error: any) {
        console.error('Error fetching parking statistics:', error);
        const errorMessage = error.error?.Error?.Message || 
                            error.error?.Errors?.[0]?.Message ||
                            error.message || 
                            'An error occurred while fetching data';
        chart.toastr.error(errorMessage);
        chart.chart.StopLoading();
      }
    }
  }


  public getReportableEntity(targetType: string): ReportableEntity | null {
    return this.ReportableEntities?.find(entity => entity.TargetType === targetType) || null;
  }

  public getSplitByOptions(targetType: string): ReportableProperty[] {
    const entity = this.getReportableEntity(targetType);
    return entity?.Properties?.filter(property => property.CanSplitBy) || [];
  }

  public GetSplitByLabel(targetType: string, propertyName: string | undefined): string {
    const entity = this.getReportableEntity(targetType);
    const property = entity?.Properties?.find(property => property.ColumnName === propertyName);
    return property?.Label || '';
  }

  public getReportableProperty(targetType: string, propertyName: string | undefined): ReportableProperty | null {
    const entity = this.getReportableEntity(targetType);
    return entity?.Properties?.find(property => property.ColumnName === propertyName) || null;
  }
}
