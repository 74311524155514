<div class="modal is-active" [formGroup]="Form">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Access Control</p>
      </header>
      <section class="modal-card-body"style="min-width: 800px;">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Access Type</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select formControlName="ClassName" name="ClassName" (change)="ClassChanged()">
                                    <option value="ControlledAreaAccessMethodPublic">Public Access</option>
                                    <option value="ControlledAreaAccessMethodWhiteList">Access List Only</option>
                                </select>
                            </div>
                            <app-validator [For]="this.Form.get('ClassName')"></app-validator>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="field is-horizontal" *ngIf="GetClassName() == 'ControlledAreaAccessMethodWhiteList'">
                <div class="field-label is-normal">
                    <label class="label">Access List</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth" *ngIf="AccessLists != null">
                                <select formControlName="PlateAccessListId" name="PlateAccessListId" (change)="AccessListChanged()">
                                    <option *ngFor="let a of AccessLists" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <span *ngIf="AccessLists == null">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Charge Strategy</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select formControlName="ChargeStrategy" name="ChargeStrategy" (change)="ChargeStrategyChanged()">
                                    <option value="None">None</option>
                                    <option value="Hold">Hold on Entry</option>
                                    <option value="PostPay">Pay at Exit</option>
                                    <option value="PrePay">Pay at Entry</option>
                                </select>
                            </div>
                            <app-validator [For]="this.Form.get('ChargeStrategy')"></app-validator>
                        </div>
                    </div>
                </div>  
            </div>

            <div class="field is-horizontal" *ngIf="ShowRateSet()">
                <div class="field-label is-normal">
                    <label class="label">Rate Set</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth" *ngIf="RateSets != null">
                                <select formControlName="RateSetId" name="RateSetId" (change)="RateSetChanged()">
                                    <option *ngFor="let a of RateSets" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <app-validator [For]="this.Form.get('RateSetId')"></app-validator>
                            <span *ngIf="RateSets == null">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>  
            </div>

            <div class="field is-horizontal" *ngIf="GetChargeStrategy() === 'Hold'">
                <div class="field-label is-normal">
                    <label class="label">Hold Amount</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <input class="input" type="number" step="0.01" formControlName="HoldChargeAmount" (change)="UpdateChargeStrategyDescription()">
                            <app-validator [For]="this.Form.get('HoldChargeAmount')"></app-validator>
                        </div>
                    </div>
                </div>  
            </div>
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-background-white" (click)="close()"><span class="icon"><i class="fas fa-times"></i></span><span>Cancel</span></button>
        <button class="button is-primary" (click)="confirm()" *ngIf="NewMethod"><span class="icon"><i class="fas fa-plus"></i></span><span>Add</span></button>
        <button class="button is-primary" (click)="confirm()" *ngIf="!NewMethod"><span class="icon"><i class="fas fa-check"></i></span><span>Update</span></button>
      </footer>
    </div>
  </div>